import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { order } from 'ols-order-webservice-typescript-client';
import { AxiosError } from 'axios';
import { InternetConnectionServices } from '@/services/internetConnectionServicesApi';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { RootState } from '@/store';
import { ServiceState } from '@/typings/ServiceState';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';
import { NetworkType } from '@/typings/NetworkType';

const initialState: ServiceState<order.InternetConnectionServiceGetResponseV1> = {
  loaded: false,
  failed: false,
  response: null,
  error: null
};

/**
 * BFF-CHP-004: 選択可能インターネット接続サービス情報取得API
 */
export const getInternetConnectionServicesV1 = createAsyncThunk<
  // Api正常時レスポンスの型
  order.InternetConnectionServiceGetResponseV1,
  // Apiリクエストパラメータの型
  {
    networkType: NetworkType;
    deviceIpadType: '1' | '2' | '3' | '4';
    // fixme 【501】 winIsSeriesDeviceCategory をEnum化する
    winIsSeriesDeviceCategory: '1' | '2' | '3' | '4' | '5';
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'internetConnectionServices/getInternetConnectionServicesV1',
  async ({ networkType, deviceIpadType, winIsSeriesDeviceCategory }, { rejectWithValue }) => {
    const config = apiConfigProvider();
    try {
      return await InternetConnectionServices.getInstance(config).getInternetConnectionServices(
        networkType,
        deviceIpadType,
        winIsSeriesDeviceCategory
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-CHP-004'));
    }
  }
);

const slice = createSlice({
  name: 'internetConnectionServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInternetConnectionServicesV1.pending, () => initialState)
      .addCase(getInternetConnectionServicesV1.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getInternetConnectionServicesV1.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

export const getInternetConnectionServicesV1Result = (
  state: RootState
): RootState['entity']['internetConnectionServicesApi'] => state.entity.internetConnectionServicesApi;

export const { actions, reducer } = slice;
