import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * リクエストパラメータ
 */
export type RequestParameters = {
  /**
   * 対象URL
   */
  targetUrl: string;
  /**
   * 商品コード
   */
  goodsStkNo: string;
  /**
   * ハッシュ値
   */
  estimateId: string;
  /**
   * 契約種別
   */
  contractType: string;
  /**
   * 電話番号
   */
  telNum: string;
  /**
   * ネットワーク区分
   */
  lineType: string;
  /**
   * ページリファラー
   */
  pageReferrer: string;
  /**
   * 注文番号
   */
  orderNum: string;
  /**
   * アフィリエイトコード1
   */
  affiliateCode1: string;
  /**
   * アフィリエイトコード2
   */
  affiliateCode2: string;
  /**
   * 代理店CD
   */
  agencyCode: string;
  /**
   * 事務手数料有無(ハッシュ値)
   */
  commission: string;
  /**
   * トスアップ受付店コード
   */
  tossUpAgencyCode: string;
  /**
   * トスアップ担当者ID
   */
  tossUpStaffCode: string;
  /**
   * トスアップ受付店名称
   */
  tossUpShopName: string;
};

const initialState: RequestParameters = {
  targetUrl: '',
  goodsStkNo: '',
  estimateId: '',
  contractType: '',
  telNum: '',
  lineType: '',
  pageReferrer: '',
  orderNum: '',
  affiliateCode1: '',
  affiliateCode2: '',
  agencyCode: '',
  commission: '',
  tossUpAgencyCode: '',
  tossUpStaffCode: '',
  tossUpShopName: ''
};

const slice = createSlice({
  name: 'requestParameter',
  initialState,
  reducers: {
    setRequestParameter: (state: RequestParameters, action: PayloadAction<Partial<RequestParameters>>) => ({
      ...state,
      ...action.payload
    }),
    resetRequestParameter: (state: RequestParameters) => ({
      ...state,
      ...initialState
    })
  }
});

export const { actions, reducer } = slice;
