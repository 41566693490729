import { order } from 'ols-order-webservice-typescript-client';
import type { RootState } from '@/store';
import { ApplyTypes } from '@/typings/ApplyType';
import { AreaCode, AreaCodeLabels } from '@/typings/AreaCode';
import { CartInfo } from '@/typings/CartInfo';
import { ContractType, ContractTypes } from '@/typings/ContractType';
import { FileLabelType } from '@/typings/IdentificationFileLabelType';
import { UqUserStatusTypes, UqUserStatusType } from '@/typings/UqStatus';
import { IdentityVerificationMethods } from '@/typings/IdentityVerificationMethod';
import { PaymentMethodsTypes } from '@/typings/PaymentMethodType';
import { ReceivingPlaceCodes } from '@/typings/ReceivingPlaceCode';
import { UseSimTypes } from '@/typings/UseSimType';
import {
  getDeviceCategory,
  getOsType,
  getSalesProductResult,
  isProductTypeOld,
  getCandidateAfterSaleServicesResult,
  getCandidateAfterSaleServicesForAppleWatchResult
} from '@/store/api/product/selectors';
import {
  getContractsResult,
  getAccountComplementsResult,
  getSelectOnlineShopSubscriberList
} from '@/store/api/user/selectors';

import { postEstimatedAmountsResult } from '@/store/api/estimate/selectors';
import { OtherCompanyInvoiceInformation } from '@/typings/OtherCompanyInvoiceInfoUpload';
import { MethodOfPayments } from '@/typings/MethodOfPayment';
import { ProductPaymentMethods } from '@/typings/ProductPaymentMethod';
import { getCandidatePlanV1Result } from '@/store/api/candidatePlanInformation/candidatePlanInformationApiSlice';
import { AfterSaleServiceCodes } from '@/typings/AfterSaleServiceCode';
import { AfterSaleServiceCodeForAppleWatches } from '@/typings/AfterSaleServiceCodeForAppleWatch';
import { ChangePrivilegeValues } from '@/typings/ChangePrivilegeValue';
import {
  BuyTerminal,
  ContractTypeGroup,
  LineContract,
  LineContractDiscount,
  Option,
  AfterCareService,
  TerminalBuyMethod,
  AccessoryBuyMethod,
  Discount,
  ContractorInfo,
  LineContractInfo,
  Identification,
  ContractAdditionInfo,
  EkycRequestParams
} from './orderSlice';

/**
 * 注文ステート取得
 * @param state - ステート
 * @returns
 */
export const getOrder = (state: RootState): RootState['order'] => state.order;

/**
 * ログインステート取得
 * @param state - ステート
 * @returns
 */
export const getLogin = (state: RootState): RootState['order']['login'] => state.order.login;

/**
 * MNPワンストップステート取得
 * @param state - ステート
 * @returns
 */
export const getMnpOnestopInfo = (state: RootState): RootState['order']['mnpOnestopInfo'] => state.order.mnpOnestopInfo;

/**
 * OpenAuId情報取得
 * @param state - ステート
 * @returns
 */
export const getAuthOpenAuIdInfo = (state: RootState): RootState['order']['authOpenAuIdInfo'] =>
  state.order.authOpenAuIdInfo;

/**
 * 所有端末ステート取得
 * @param state - ステート
 * @returns
 */
export const getOwnedTerminal = (state: RootState): RootState['order']['ownedTerminal'] => state.order.ownedTerminal;

/**
 * 見積ステート取得
 * @param state - ステート
 * @returns
 */
export const getEstimate = (state: RootState): RootState['order']['estimate'] => state.order.estimate;

/**
 * 契約種別グループステート取得
 * @param state - ステート
 * @returns
 */
export const getContractTypeGroup = (state: RootState): ContractTypeGroup | undefined =>
  state.order.estimate?.contractTypeGroup;

/**
 * 購入端末ステート取得
 * @param state - ステート
 * @returns
 */
export const getBuyTerminal = (state: RootState): BuyTerminal | undefined => state.order.estimate?.buyTerminal;

/**
 * 回線契約ステート取得
 * @param state - ステート
 * @returns
 */
export const getLineContract = (state: RootState): LineContract | undefined => state.order.estimate?.lineContract;

/**
 * 回線契約値引ステート取得
 * @param state - ステート
 * @returns
 */
export const getLineContractDiscount = (state: RootState): LineContractDiscount | undefined =>
  state.order.estimate?.lineContractDiscount;

/**
 * オプションステート取得
 * @param state - ステート
 * @returns
 */
export const getOption = (state: RootState): Option | undefined => state.order.estimate?.option;

/**
 * 保証サービスステート取得
 * @param state - ステート
 * @returns
 */
export const getAfterCareService = (state: RootState): AfterCareService | undefined =>
  state.order.estimate?.afterCareService;

/**
 * 端末購入方法ステート取得
 * @param state - ステート
 * @returns
 */
export const getTerminalBuyMethod = (state: RootState): TerminalBuyMethod | undefined =>
  state.order.estimate?.terminalBuyMethod;

/**
 * アクセサリー購入方法ステート取得
 * @param state - ステート
 * @returns
 */
export const getAccessoryBuyMethod = (state: RootState): AccessoryBuyMethod | undefined =>
  state.order.estimate?.accessoryBuyMethod;

/**
 * 割引ステート取得
 * @param state - ステート
 * @returns
 */
export const getDiscount = (state: RootState): Discount | undefined => state.order.estimate?.discount;

/**
 * 契約ステート取得
 * @param state - ステート
 * @returns
 */
export const getContract = (state: RootState): RootState['order']['contract'] => state.order.contract;

/**
 * 契約者情報ステート取得
 * @param state - ステート
 * @returns
 */
export const getContractorInfo = (state: RootState): ContractorInfo | undefined => state.order.contract?.contractorInfo;

/**
 * 回線契約情報ステート取得
 * @param state - ステート
 * @returns
 */
export const getLineContractInfo = (state: RootState): LineContractInfo | undefined =>
  state.order.contract?.lineContractInfo;

/**
 * 本人確認ステート取得
 * @param state - ステート
 * @returns
 */
export const getIdentification = (state: RootState): Identification | undefined => state.order.contract?.identification;

/**
 * 本人確認ステート取得
 * @param state - ステート
 * @returns
 */
export const getEkycParams = (state: RootState): EkycRequestParams | undefined =>
  state.order.contract?.identification?.ekycRequestParam;

/**
 * 本人確認書類画像リストステート取得
 * @param state - ステート
 * @returns
 */
export const getIdentificationImageList = (state: RootState): Record<FileLabelType, string> | undefined =>
  state.order.contract?.identification?.identificationImageList;

/**
 * 契約付加情報ステート取得
 * @param state - ステート
 * @returns
 */
export const getContractAdditionInfo = (state: RootState): ContractAdditionInfo | undefined =>
  state.order.contract?.contractAdditionInfo;

/**
 * 決済ステート取得
 * @param state - ステート
 * @returns
 */
export const getPayment = (state: RootState): RootState['order']['payment'] => state.order.payment;

/**
 * 注文付加情報ステート取得
 * @param state - ステート
 * @returns
 */
export const getOrderAdditionInfo = (state: RootState): RootState['order']['orderAdditionInfo'] =>
  state.order.orderAdditionInfo;

/**
 * 契約種別取得
 * @param state - ステート
 * @returns
 */
export const getContractType = (state: RootState): ContractType =>
  state.order.estimate?.contractTypeGroup?.contractType as ContractType;

/*
 * 見積ステート（無料同梱品カート情報）取得
 * @param state - ステート
 * @returns
 */
export const getFreeBundledGoodsCartInfo = (state: RootState): CartInfo | undefined =>
  state.order.estimate?.accessory?.freeBundledGoodsCartInfo;

/**
 * 見積ステート（オプション品カート情報リスト）取得
 * @param state - ステート
 * @returns
 */
export const getOptionalGoodsCartInfoList = (state: RootState): Record<string, CartInfo> | undefined =>
  state.order.estimate?.accessory?.optionalGoodsCartInfoList;

/**
 * リクエストパラメータ（代理店コード）取得
 * @param state - ステート
 * @returns
 */
export const getAgencyCode = (state: RootState): string | undefined => state.order.orderAdditionInfo?.agencyCode;

/**
 * OCTユーザ判定区分取得
 * @param state - ステート
 * @returns
 */
export const getOctUserType = (state: RootState): string | undefined =>
  state.order.estimate?.contractTypeGroup?.octUserType;

/**
 * UQ新旧ステータス取得
 * @param state - ステート
 * @returns
 */
export const getUqUserStatus = (state: RootState): UqUserStatusType | undefined =>
  state.order.estimate?.contractTypeGroup?.uqUserStatus;

/**
 * 旧UQ判定
 * @param state - ステート
 * @returns
 */
export const isOldUqUser = (state: RootState): boolean => getUqUserStatus(state) === UqUserStatusTypes.oldUq;

/**
 * 契約種別が機種変更か判定
 * @param state - ステート
 * @returns
 */
export const isContractTypeChange = (state: RootState): boolean =>
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.Change;

/**
 * 契約種別が新規契約か判定
 * @param state - ステート
 * @returns
 */
export const isContractTypeNew = (state: RootState): boolean =>
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.New;

/**
 * 契約種別がMNPか判定
 * @param state - ステート
 * @returns
 */
export const isContractTypeMnp = (state: RootState): boolean =>
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.Mnp;

/**
 * 契約種別がオープン新規か判定
 * @param state - ステート
 * @returns
 */
export const isContractTypeNewOpen = (state: RootState): boolean =>
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.NewOpen;

/**
 * 契約種別が番号移行か判定
 * @param state - ステート
 * @returns
 */
export const isNumberMigration = (state: RootState): boolean =>
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.NumberMigration;
/**
 * 契約種別が番号移行かつ旧UQか判定
 * @param state - ステート
 * @returns
 */
export const isChangeOldUqUser = (state: RootState): boolean =>
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.NumberMigration && isOldUqUser(state);

/**
 * auユーザーから機種変更の判定
 * @param state - ステート
 * @returns
 */
export const isContractChangeOfAuUser = (state: RootState): boolean =>
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.Change && !!getUqUserStatus(state);
export const isContractTypeNumberMigration = (state: RootState): boolean =>
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.NumberMigration;

/**
 * 契約種別が新規orMNPか判定
 * @param state - ステート
 * @returns
 */
export const isContractTypeNewOrMnp = (state: RootState): boolean =>
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.New ||
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.Mnp;

/**
 * 契約種別が新規orMNPor新規オープンか判定
 * @param state - ステート
 * @returns
 */
export const isContractTypeNewOrMnpOrNewOpen = (state: RootState): boolean =>
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.New ||
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.Mnp ||
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.NewOpen;

/**
 * 契約種別が新規orMNPor新規オープンor番号移行か判定
 * @param state - ステート
 * @returns
 */
export const isContractTypeNewOrMnpOrNewOpenOrNumber = (state: RootState): boolean =>
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.New ||
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.Mnp ||
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.NewOpen ||
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.NumberMigration;

/**
 * 契約種別が新規orMNPor番号移行か判定
 * @param state - ステート
 * @returns
 */
export const isNewMnpNumberMigrationContract = (state: RootState): boolean =>
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.New ||
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.Mnp ||
  state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.NumberMigration;

/**
 * 他社請求書情報アップロード対象フラグ
 * @param state - ステート
 */
export const isOtherCompanyInvoiceInfoUpload = (state: RootState): boolean =>
  state.order.estimate?.lineContract?.otherCompanyInvoiceInfoUpload ===
  OtherCompanyInvoiceInformation.OtherCompanyInvoiceInfo;

/**
 * GTM端末申込区分取得
 * @param state - ステート
 * @returns
 */
export const getMobileAppliClassGTM = (state: RootState): string => {
  let mobileAppliClass = '';

  if (isContractTypeNew(state)) {
    mobileAppliClass = 'new';
  }
  if (isContractTypeChange(state)) {
    mobileAppliClass = 'changes';
  }
  if (isContractTypeMnp(state)) {
    mobileAppliClass = 'MNP';
  }
  if (isContractTypeNumberMigration(state)) {
    mobileAppliClass = 'numberSwitch';
  }

  if (mobileAppliClass && state.order.estimate?.contractTypeGroup?.quickFlag) {
    mobileAppliClass += '_サクッと';
  }

  if (mobileAppliClass && state.order.estimate?.contractTypeGroup?.applyKbn === ApplyTypes.MainApply) {
    mobileAppliClass += '_本申し込み';
  }

  return mobileAppliClass;
};

/**
 * BCPオプションの場合、GTM契約オプション取得
 * @param state - ステート
 * @returns
 */
export const getContractOptionsGTM = (state: RootState): string => {
  const contractOptions: string[] = [];
  // youtubePremium
  const youtubePremium = getContractsResult(state).response?.youtubePremium?.youtubePremium;
  // auSmartPass
  const auSmartPass = getAccountComplementsResult(state).response?.auSmartPass?.auSmartPass;

  const onlineShopSubscriberInfo = getSelectOnlineShopSubscriberList(state);
  const kCategoryOptions = onlineShopSubscriberInfo?.kCategoryOptions;
  const esimLineInfoFlag = getContractTypeGroup(state)?.esimLineInfoFlag;

  if (isContractTypeNewOrMnp(state) && !esimLineInfoFlag) {
    return contractOptions.join();
  }
  if (youtubePremium === 'Y') {
    contractOptions.push('YouTubePremium');
  }

  if (auSmartPass === order.AuSmartPassContractStatusBffEnum.AU_SMART_PASS_PREMIUM) {
    contractOptions.push('スマパスプレミアム');
  }

  if (kCategoryOptions?.includes('K214')) {
    contractOptions.push('副回線');
  }

  return contractOptions.join();
};

/**
 * GTM加入済みオプション情報の数を取得
 * @param state - ステート
 * @returns
 */
export const getContractOptionsCountGTM = (state: RootState): string => {
  let contractOptionsCount = 0;

  // AppleMusic
  const appleMusic = getContractsResult(state).response?.appleMusic?.appleMusic;
  // youtubePremium
  const youtubePremium = getContractsResult(state).response?.youtubePremium?.youtubePremium;
  // auSmartPass
  const auSmartPass = getAccountComplementsResult(state).response?.auSmartPass?.auSmartPass;
  // auBicycleSupport
  const auBicycleSupport = getAccountComplementsResult(state).response?.auBicycleSupport?.auBicycleSupport;

  if (
    auSmartPass === order.AuSmartPassContractStatusBffEnum.AU_SMART_PASS_PREMIUM ||
    auSmartPass === order.AuSmartPassContractStatusBffEnum.AU_SMART_PASS
  ) {
    contractOptionsCount += 1;
  }

  if (auBicycleSupport === order.AuBicycleSupportContractStatusBffEnum.JOIN) {
    contractOptionsCount += 1;
  }

  if (appleMusic === order.AppleMusicStatusBffEnum.JOIN) {
    contractOptionsCount += 1;
  }

  if (youtubePremium === order.YoutubePremiumTypeBffEnum.JOIN) {
    contractOptionsCount += 1;
  }

  return contractOptionsCount.toString();
};

/**
 * GTMデータプラン
 * @param state - ステート
 * @returns
 */
export const getContractPlanGTM = (state: RootState): string => {
  let contractPlan = '';
  const onlineShopSubscriberInfo = getSelectOnlineShopSubscriberList(state);
  const dataPlanName = onlineShopSubscriberInfo?.dataPlanName;

  if (dataPlanName) {
    contractPlan = dataPlanName;
  }
  return contractPlan;
};

/**
 * 注文完了した注文の料金プランのGTM
 * @param state - ステート
 * @returns
 */
export const getSelectedRatePlanGTM = (state: RootState): string => {
  let ratePlan = '';

  // 選択可能料金プラン一覧取得
  const candidatePlanList = getCandidatePlanV1Result(state).response?.planList;

  // 選択された料金プランを取得
  const dataPlanCode = getLineContract(state)?.dataPlanCode;
  const selectedRatePlan = candidatePlanList?.find((e) => e.dataPlanCode === dataPlanCode);
  const displayDataPlanName = selectedRatePlan?.displayDataPlanName;

  if (displayDataPlanName) {
    ratePlan = displayDataPlanName;
  }

  return ratePlan;
};

/**
 * 注文完了した注文の補償サービスのGTM
 * @param state - ステート
 * @returns
 */
export const getAssuranceSupportGTM = (state: RootState): string => {
  let assuranceSupport = '';
  const afterCareService = getAfterCareService(state);

  // BFF-PRD-002: 保証・サポートサービス取得API 結果取得
  const candidateAfterSaleServicesList = getCandidateAfterSaleServicesResult(state).response?.afterSaleServiceList;
  const afterCareSaleServiceCode = candidateAfterSaleServicesList?.find(
    (e) => e.afterCareSaleServiceCode === afterCareService?.afterCareSaleServiceCode
  );
  const afterCareSaleServiceName = afterCareSaleServiceCode?.afterCareSaleServiceName;

  // BFF-PRD-007: AppleWatch用保証・サポートサービス取得API 結果取得
  const candidateAfterSaleServicesForAppleWatchList = getCandidateAfterSaleServicesForAppleWatchResult(state).response
    ?.afterSaleServiceForAppleWatchList;
  const afterCareSaleServiceCodeForAppleWatch = candidateAfterSaleServicesForAppleWatchList?.find(
    (e) => e.afterCareSaleServiceCode === afterCareService?.afterCareSaleServiceCodeForAppleWatch
  );
  const afterCareSaleServiceCodeForAppleWatchName = afterCareSaleServiceCodeForAppleWatch?.afterCareSaleServiceName;

  if (afterCareSaleServiceName) {
    assuranceSupport = afterCareSaleServiceName;
  }
  if (afterCareSaleServiceCodeForAppleWatchName) {
    assuranceSupport = afterCareSaleServiceCodeForAppleWatchName;
  }
  if (
    afterCareSaleServiceCode?.afterCareSaleServiceCode === AfterSaleServiceCodes.NOT_APPLY ||
    afterCareSaleServiceCodeForAppleWatch?.afterCareSaleServiceCode === AfterSaleServiceCodeForAppleWatches.NOT_APPLY
  ) {
    assuranceSupport = '';
  }

  return assuranceSupport;
};

/**
 * GTMスマトクプログラムの加入状況を取得
 * @param state - ステート
 * @returns
 */
export const getResidualValuePaymentProgramGTM = (state: RootState): string => {
  const changePrivilege = getTerminalBuyMethod(state)?.changePrivilege;
  let residualValuePaymentProgram = 'N';
  if (changePrivilege === ChangePrivilegeValues.Apply) {
    residualValuePaymentProgram = 'Y';
  }
  return residualValuePaymentProgram;
};

/**
 * GTM受取方法の種別取得
 * @param state - ステート
 * @returns
 */
export const getPickupLocationTypeGTM = (state: RootState): string => {
  const orderAdditionInfo = getOrderAdditionInfo(state);

  let pickupLocationType = '';
  if (orderAdditionInfo?.receivingPlace === ReceivingPlaceCodes.AT_AU_SHOP) {
    pickupLocationType = 'shop_簡易';
  }
  if (orderAdditionInfo?.receivingPlace === ReceivingPlaceCodes.CONTRACTOR_ADDRESS) {
    pickupLocationType = 'home_契約者_簡易';
  }
  if (orderAdditionInfo?.receivingPlace === ReceivingPlaceCodes.INVOICE_ADDRESS) {
    pickupLocationType = 'home_請求書送付先_簡易';
  }

  return pickupLocationType;
};

/**
 * GTMユーザーが選択した本人確認方法取得
 * @param state - ステート
 * @returns
 */
export const getIdCheckMethodGTM = (state: RootState): string => {
  const identification = getIdentification(state);

  let idCheckMethod = '';
  if (identification?.identityVerificationMethodByPhotoOrUpload === IdentityVerificationMethods.E_KYC) {
    idCheckMethod = 'eKYC';
  }

  if (identification?.identityVerificationMethodByPhotoOrUpload === IdentityVerificationMethods.MAIL) {
    idCheckMethod = 'post';
  }

  if (identification?.identityVerificationMethodByPhotoOrUpload === IdentityVerificationMethods.UPLOAD) {
    idCheckMethod = 'upload';
  }

  return idCheckMethod;
};

/**
 * GTM注文区分取得
 * @param state - ステート
 */
export const getOrderTypeGTM = (state: RootState): string => {
  let orderType = 'order';
  if (state.order.estimate?.contractTypeGroup?.applyKbn === ApplyTypes.MainApply) {
    orderType = 'reservation';
  }
  return orderType;
};

/**
 * GTMポイント使用の有無取得
 * @param state - ステート
 * @returns
 */
export const getUsePointUmuGTM = (state: RootState): string => {
  const discount = getDiscount(state);
  let usePoint = 'N';
  if (discount?.usePoint && discount?.usePoint > 0) {
    usePoint = 'Y';
  }
  return usePoint;
};

/**
 * GTMクーポン使用の有無取得
 * @param state - ステート
 * @returns
 */
export const getUseCouponUmuGTM = (state: RootState): string => {
  const discount = getDiscount(state);
  let useCoupon = 'N';
  if (discount?.useCoupon) {
    useCoupon = 'Y';
  }
  return useCoupon;
};

/**
 * GTMスマホSIMの種別取得
 * @param state - ステート
 * @returns
 */
export const getSimTypeGTM = (state: RootState): string => {
  const buyTerminal = getBuyTerminal(state);
  const category = getDeviceCategory(state);
  const productSimType = getSalesProductResult(state)?.response?.simType;

  // 新規Open
  const isNewOpenContract = isContractTypeNewOpen(state);
  let simType = '';
  if (buyTerminal?.useSim === UseSimTypes.SimIcCard) {
    simType = 'SIM';
  } else if (buyTerminal?.useSim === UseSimTypes.ESim) {
    simType = 'eSIM';
  } else if (category === order.DeviceCategoryBffEnum.SIM) {
    if (productSimType === UseSimTypes.ESim) {
      simType = 'eSIM';
    } else {
      simType = 'SIM';
    }
  } else if (isNewOpenContract) {
    simType = '';
  } else {
    // 中古SP、FP
    simType = 'SIM';
  }

  return simType;
};

/**
 * GTM併売取得
 * @param state - ステート
 * @returns
 */
export const getSoldTogetherGTM = (state: RootState): string => {
  const optionalGoodsCartInfoList = getOptionalGoodsCartInfoList(state);
  let soldTogether = 'N';
  if (Object.values(optionalGoodsCartInfoList || {}).length > 0) {
    soldTogether = 'Y';
  }
  return soldTogether;
};

/**
 * GTMユーザーが選択した都道府県名取得
 * @param state - ステート
 * @returns
 */
export const getPrefecturesGTM = (state: RootState): string => {
  const contractorInfo = getContractorInfo(state);
  const contractorPrefecture = contractorInfo?.contractorPrefecture as AreaCode;
  return AreaCodeLabels[contractorPrefecture] || '';
};

/**
 * GTM支払種別取得
 * @param paymentProductTaxPrice - 機種対象金額（税込）
 * @returns
 */
export const getPaymentTypeGTM = (paymentProductTaxPrice = 0) => (state: RootState): string => {
  let paymentType = '';

  const usePointUmu = getUsePointUmuGTM(state);

  const paymentMethods = getTerminalBuyMethod(state)?.paymentMethods;

  if (paymentMethods === PaymentMethodsTypes.LumpSumPayment) {
    if (paymentProductTaxPrice === 0 && usePointUmu === 'Y') {
      paymentType = 'point';
    } else {
      paymentType = 'credit';
    }
  } else {
    paymentType = 'bunkatsu';
  }

  return paymentType;
};

/**
 * GTM月々お支払種別取得
 * @param state - ステート
 * @returns
 */
export const getMonthlyPaymentTypeGTM = (state: RootState): string => {
  let monthlyPaymentType = '';
  const monthlyPaymentMethod = getPayment(state)?.methodOfPayments;
  if (monthlyPaymentMethod === MethodOfPayments.CREDIT_CARD) {
    monthlyPaymentType = 'credit';
  }
  if (monthlyPaymentMethod === MethodOfPayments.ACCOUNT_TRANSFER) {
    monthlyPaymentType = 'koufuri';
  }
  return monthlyPaymentType;
};

/**
 * GTM商品お支払種別取得
 * @returns
 * @param state - ステート
 */
export const getDevicePaymentTypeGTM = (state: RootState): string => {
  let devicePaymentType = '';
  const productPaymentMethod = getPayment(state)?.productPaymentMethod;
  if (productPaymentMethod === ProductPaymentMethods.CREDIT_CARD) {
    devicePaymentType = 'credit';
  }
  if (productPaymentMethod === ProductPaymentMethods.CASH_ON_DELIVERY) {
    devicePaymentType = 'daibiki';
  }
  return devicePaymentType;
};

/**
 * GTM購入商品データ取得
 * @param state - ステート
 */
export const getEcommerceProductsGTM = (
  state: RootState
): {
  name: string;
  id: string;
  price: number;
  quantity: number;
}[] => {
  const optionalGoodsCartInfoList = Object.values(getOptionalGoodsCartInfoList(state) || {});

  return (
    optionalGoodsCartInfoList?.map((cart) => ({
      name: cart.accessoryName,
      id: cart.productStockCode,
      price: cart.accessoryTaxPrice,
      quantity: cart.purchaseCount
    })) || []
  );
};

/**
 * 商品コード取得
 * @param state - ステート
 * @returns
 */
export const getGoodsStockNo = (state: RootState): string | undefined =>
  state.order.estimate?.buyTerminal?.goodsStockNo;

export const getProductDTM = (state: RootState): string => {
  const orderGoodsStockNo = getGoodsStockNo(state);
  const listProductCode = [];

  if (orderGoodsStockNo) {
    listProductCode.push(orderGoodsStockNo);
  }

  return listProductCode.toString() || '';
};

export const getTerminalTypeDTM = (state: RootState): string => {
  const orderEstimate = getEstimate(state);
  const applyKbn = orderEstimate?.contractTypeGroup?.applyKbn;
  const contractTypeGroup = orderEstimate?.contractTypeGroup?.contractType;
  let terminalType = '';

  if (applyKbn && contractTypeGroup === ContractTypes.New) {
    terminalType = applyKbn === ApplyTypes.Other ? 'new' : 'new_本申し込み';
  }
  if (applyKbn && contractTypeGroup === ContractTypes.Change) {
    terminalType = applyKbn === ApplyTypes.Other ? 'changes' : 'changes_本申し込み';
  }
  if (applyKbn && contractTypeGroup === ContractTypes.Mnp) {
    terminalType = applyKbn === ApplyTypes.Other ? 'MNP' : 'MNP_本申し込み';
  }
  if (applyKbn && contractTypeGroup === ContractTypes.NumberMigration) {
    terminalType = applyKbn === ApplyTypes.Other ? 'numberSwitch' : 'numberSwitch_本申し込み';
  }
  return terminalType;
};

export const getProductTypeDTM = (state: RootState): string => {
  const deviceCategory = getDeviceCategory(state);
  const productApiResult = getSalesProductResult(state).response;
  const productTypeOld = isProductTypeOld(state);
  const osType = getOsType(state);
  let productType = '';

  // Start productType
  if (deviceCategory === order.DeviceCategoryBffEnum.FP) {
    productType = 'FP';
  }

  if (deviceCategory === order.DeviceCategoryBffEnum.ROUTER_5G) {
    productType = 'Data';
  }

  if (deviceCategory === order.DeviceCategoryBffEnum.SP && productTypeOld) {
    productType = osType === order.OsTypeBffEnum.APPLE ? 'au_Certified-SP-iPhone' : 'au_Certified-SP-Android';
  }

  if (deviceCategory === order.DeviceCategoryBffEnum.SP && !productTypeOld) {
    productType = osType === order.OsTypeBffEnum.APPLE ? 'SP-iPhone' : 'SP-Android';
  }

  if (deviceCategory === order.DeviceCategoryBffEnum.TABLET) {
    productType = osType === order.OsTypeBffEnum.APPLE ? 'Tab-iPad' : 'Tab-Android';
  }

  if (
    productApiResult &&
    deviceCategory === order.DeviceCategoryBffEnum.SIM &&
    productApiResult.simType === order.SimTypeBffEnum.EMBEDDED_SIM
  ) {
    productType = 'eSIM';
  }
  if (
    productApiResult &&
    deviceCategory === order.DeviceCategoryBffEnum.SIM &&
    productApiResult.simType !== order.SimTypeBffEnum.EMBEDDED_SIM
  ) {
    productType = 'SIM';
  }
  // end productType
  return productType;
};

export const getPurchaseIdDTM = (state: RootState): string => {
  let purchaseID = '';
  const orderState = state.order;
  if (orderState?.ordNo) {
    purchaseID = orderState?.ordNo;
  }
  return purchaseID;
};

export const getGoodCartInfoListDTM = (state: RootState): string => {
  let hasGoodsCartInfoList = 'NO';
  const optionalGoodsCartInfoList = getOptionalGoodsCartInfoList(state);

  if (optionalGoodsCartInfoList) {
    hasGoodsCartInfoList = Object.keys(optionalGoodsCartInfoList).length > 0 ? 'Y' : 'N';
  }
  return hasGoodsCartInfoList;
};

export const getIdentificationVerificationDTM = (state: RootState): string => {
  const identification = getIdentification(state);
  let identificationVerification = '';
  if (identification?.identityVerificationMethodByPhotoOrUpload === '0') {
    identificationVerification = 'eKYC';
  }

  if (identification?.identityVerificationMethodByPhotoOrUpload === '1') {
    identificationVerification = 'upload';
  }

  if (identification?.identityVerificationMethodByPhotoOrUpload === '2') {
    identificationVerification = 'post';
  }
  return identificationVerification;
};

export const getAreaNameDTM = (state: RootState): string => {
  let areaName = '';
  const orderEstimate = getEstimate(state);
  const contractorInfo = getContractorInfo(state);
  const applyKbn = orderEstimate?.contractTypeGroup?.applyKbn;
  if (applyKbn && contractorInfo?.contractorPrefecture) {
    areaName = AreaCodeLabels[contractorInfo.contractorPrefecture as AreaCode];
  }
  return areaName;
};

export const getDiscountDTM = (state: RootState): string => {
  const orderDiscount = getDiscount(state);
  let discount = 'N';
  if (orderDiscount?.usePoint) {
    discount = orderDiscount.usePoint > 0 ? 'Y' : 'N';
  }
  return discount;
};

export const getCouponMeasureNoDTM = (state: RootState): string => {
  const orderDiscount = getDiscount(state);
  return orderDiscount?.useCoupon ? 'Y' : 'N';
};

export const getOrderTypeDTM = (state: RootState): string => {
  const orderEstimate = getEstimate(state);
  const applyKbn = orderEstimate?.contractTypeGroup?.applyKbn;
  return applyKbn === '2' ? 'reservation' : 'order';
};

export const getEventsDTM = (state: RootState): string => {
  const productType = getProductTypeDTM(state);
  let events = '';

  // Start events
  if (productType === 'FP') {
    events = 'event78,event143';
  }

  if (productType === 'Data') {
    events = 'event78,event154';
  }

  if (productType === 'au_Certified-SP-iPhone') {
    events = 'event78,event148,event152';
  }

  if (productType === 'au_Certified-SP-Android') {
    events = 'event78,event149,event152';
  }

  if (productType === 'SP-iPhone') {
    events = 'event78,event141,event151';
  }

  if (productType === 'SP-Android') {
    events = 'event78,event142,event151';
  }

  if (productType === 'Tab-iPad') {
    events = 'event78,event144,event153';
  }

  if (productType === 'Tab-Android') {
    events = 'event78,event145,event153';
  }

  if (productType === 'SIM') {
    events = 'event78,event150';
  }

  if (productType === 'eSIM') {
    events = 'event78,event155';
  }
  // End events
  return `purchase,${events}`;
};

export const getPaymentTypeDTM = (state: RootState): string => {
  const paymentMethods = getTerminalBuyMethod(state)?.paymentMethods;
  const orderDiscount = getDiscount(state);
  const estimatedAmountInfo = postEstimatedAmountsResult(state).response;

  let paymentType = '';
  // payment methods
  if (paymentMethods !== PaymentMethodsTypes.LumpSumPayment) {
    paymentType = 'bunkatsu';
  }

  if (
    paymentMethods === PaymentMethodsTypes.LumpSumPayment &&
    orderDiscount?.usePoint &&
    estimatedAmountInfo?.monthlyCostTotalTaxPrice === 0 &&
    (orderDiscount?.usePoint ?? 0) > 0
  ) {
    paymentType = 'point';
  }

  if (
    paymentMethods === PaymentMethodsTypes.LumpSumPayment &&
    estimatedAmountInfo?.monthlyCostTotalTaxPrice !== 0 &&
    (orderDiscount?.usePoint ?? 0) <= 0
  ) {
    paymentType = 'credit';
  }
  return paymentType;
};
