import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from 'ols-order-components';
import { ScreenUrlPaths } from '@/typings/ScreenUrlPath';
import logo from '@/assets/images/logo.png';
import { REACT_APP_AU_TOP_URL } from '@/config/env';

/**
 * Header Wrapperコンポーネント
 * @returns
 */
export const HeaderWrapComponent: React.FC = () => {
  const { pathname } = useLocation();
  const props: Omit<Parameters<typeof Header>[0], 'children'> = useMemo(() => {
    if (pathname.indexOf(ScreenUrlPaths.SystemError) >= 0 || pathname.indexOf(ScreenUrlPaths.BusinessError) >= 0) {
      return {
        onClickLink: () => {
          window.location.href = REACT_APP_AU_TOP_URL || '';
        }
      };
    }
    return {};
  }, [pathname]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Header {...props}>
      {/* NOTE: UQ向けの指定なのでdevelop追いつきの際、au向けの指定で上書きしないように。 */}
      <img src={logo} alt="UQ mobile" height="auto" width="46" />
    </Header>
  );
};

export const HeaderWrap = React.memo(HeaderWrapComponent);
