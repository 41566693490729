import { order } from 'ols-order-webservice-typescript-client';
import { GenderLabels, SexType } from '@/typings/Gender';
import { UsePersonSameValues } from '@/typings/UsePersonSameValue';
import { UqUserStatusTypes } from '@/typings/UqStatus';
import { OldUqContractorCategory } from '@/typings/OldUqContractorCategory';
import { divideName } from './divideName';
import { hanToZenOrEmpty } from './char';

type ContractInfo = {
  /**
   * 旧UQユーザ区分：
   * 旧UQユーザ: true
   * それ以外:false
   */
  isOldUqUser: boolean;
  /**
   * 契約者・性：
   * UQユーザー: [漢字氏名（姓） 契約者の姓]、
   * au・新UQユーザー: [漢字氏名（姓）]
   */
  contractorSei: string;
  /**
   * 契約者・名：
   * UQユーザー: [漢字氏名（名） 契約者の姓]、
   * au・新UQユーザー: [漢字氏名（名）]
   */
  contractorMei: string;
  /**
   * 契約者・性名：
   * UQユーザー: [漢字氏名（姓） 契約者の姓]+[漢字氏名（名） 契約者の名]、
   * au・新UQユーザー: [漢字氏名（姓名）]
   */
  contractorSeiMei: string;
  contractorSeiMeiFull: string;
  /**
   * 契約者・性：
   * UQユーザー: [カナ氏名（姓） 契約者の姓]、
   * au・新UQユーザー: [カナ氏名（姓）]
   */
  contractorSeiKana: string;
  /**
   * 契約者・性：
   * UQユーザー: [カナ氏名（名） 契約者の姓]、
   * au・新UQユーザー: [カナ氏名（名）]
   */
  contractorMeiKana: string;
  /**
   * 契約者・性名：
   * UQユーザー: [カナ氏名（姓） 契約者の姓]+[カナ氏名（名） 契約者の名]、
   * au・新UQユーザー: [カナ氏名（姓名）]
   */
  contractorSeiMeiKana: string;
  contractorSeiMeiKanaFull: string;
  /**
   * 契約者・生年月日：
   * UQユーザー: [生年月日 契約者の生年月日 形式：YYYYmmDD]、
   * au・新UQユーザー: [生年月日]
   */
  contractorBirthday: string;
  usePersonSame: string;
  /**
   * 利用者・生年月日：
   * UQユーザー: 該当項目なし、
   * au・新UQユーザー: [利用者生年月日]
   */
  userBirthday?: string;
  /**
   * 契約者・郵便番号：
   * UQユーザー: [郵便番号 契約者の郵便番号]、
   * au・新UQユーザー: [顧客郵便番号]
   */
  contractorPost?: string;
  /**
   * 契約者・住所：
   * UQユーザー: [契約者住所１ 契約者の住所]+[契約者住所2 契約者の住所]+[契約者住所3 契約者の住所]、
   * au・新UQユーザー: [顧客住所]
   */
  customerAddress?: string;
  /**
   * 契約者・電話番号：
   * UQユーザー: [連絡先電話番号１ 契約者の連絡先電話番号1]、
   * au・新UQユーザー: [顧客電話番号]
   */
  contractorTelephoneNumber?: string;
  /**
   * 電話番号：
   * UQユーザー: [契約電話番号 契約回線の電話番号]、
   * au・新UQユーザー: [au電話番号]
   */
  telephoneNumber?: string;
  /**
   * 請求先住所：
   * UQユーザー: 該当項目なし、
   * au・新UQユーザー: [請求先住所]
   */
  billingAddress?: string;
  /**
   * 性別：
   * UQユーザー: [性別]、
   * au・新UQユーザー: [性別]
   */
  contractorGender?: SexType;
  /**
   * 契約者・メールアドレス：
   * UQユーザー: [連絡先メールアドレス 契約者の連絡先メールアドレス]、
   * au・新UQユーザー: [顧客ＥZメールアドレス]
   */
  contractorEmailAddress?: string;
  /**
   * MVNO事業者識別コード：
   * UQユーザー: [MVNO事業者識別コード 通信事業者を識別するコード]、
   * au・新UQユーザー: [MVNO事業者識別コード]
   */
  mvnoCode?: string;
  /**
   * 契約支社：
   * UQユーザー: 該当項目なし、
   * au・新UQユーザー: [契約支社]
   */
  companyType?: order.ConSsyBffEnum;
  usePersonLastName?: string;
  usePersonFirstName?: string;
  usePersonFullName?: string;
  usePersonLastNameKana?: string;
  usePersonFirstNameKana?: string;
  usePersonFullNameKana?: string;
  billingPostalCode?: string;
  contractorCategory?: OldUqContractorCategory;
  smartPhoneStartPlanAvailable?: boolean;
};

const defaultValues = (): ContractInfo => ({
  isOldUqUser: false,
  contractorSei: '',
  contractorMei: '',
  contractorSeiMei: '',
  contractorSeiMeiFull: '',
  contractorSeiKana: '',
  contractorMeiKana: '',
  contractorSeiMeiKana: '',
  contractorSeiMeiKanaFull: '',
  contractorBirthday: '',
  contractorTelephoneNumber: '',
  telephoneNumber: undefined,
  usePersonSame: '',
  userBirthday: undefined,
  contractorPost: undefined,
  customerAddress: undefined,
  billingAddress: undefined,
  contractorEmailAddress: undefined,
  contractorGender: undefined,
  mvnoCode: undefined,
  companyType: undefined,
  usePersonLastName: undefined,
  usePersonFirstName: undefined,
  usePersonFullName: undefined,
  usePersonLastNameKana: undefined,
  usePersonFirstNameKana: undefined,
  usePersonFullNameKana: undefined,
  billingPostalCode: undefined,
  smartPhoneStartPlanAvailable: undefined
});
const NAME_FULL_SPACE = '　';

const getSexType = (cd: string): SexType | undefined => {
  if (cd === GenderLabels.Male || cd === order.GenderBffEnum.MALE) {
    return order.GenderBffEnum.MALE;
  }
  if (cd === GenderLabels.Female || cd === order.GenderBffEnum.FEMALE) {
    return order.GenderBffEnum.FEMALE;
  }

  return undefined;
};

/**
 * 顧客情報取得
 * @remarks
 * 契約種別とアカウント情報取得APIの返却値から顧客情報の取得元を判別し返却します。
 * LEON⇒stateへの設定時に変換・編集が必要ある場合はここで変換・編集を行う。
 * @param isNumberMigration - 番号移行判定フラグ
 * @param accountInfo - BFF-USR-001:アカウント取得APIレスポンス
 * @param telNum - 選択電話番号
 */
export const getCustomerInformation = (
  isContractTypeChange: boolean,
  contractChangeJudgeFlg: boolean | undefined,
  accountInfo: order.AccountGetResponseV1 | null,
  telNum?: string
): ContractInfo => {
  const onlineShopSubscriberInfo =
    accountInfo?.user?.onlineShopSubscriberList?.find((v) => v.auPhoneNo?.toString() === telNum) ??
    accountInfo?.user?.onlineShopSubscriberList?.find((v) => v.uqUserStatus === UqUserStatusTypes.oldUq);
  // 旧UQ情報のリスト取得
  const contractInfo = defaultValues();
  if (telNum && contractChangeJudgeFlg && accountInfo?.uqUser?.line?.phoneNumber === telNum && isContractTypeChange) {
    contractInfo.isOldUqUser = true;
    // 機種変更かつ旧UQの場合はLEONから顧客情報取得を取得する。
    const uqUser = accountInfo?.uqUser;
    // LEONからの返却値がC21注文登録セット対象項目の桁数を超えてしまう場合を考慮し、
    // ①不要な後ろ空白を削除
    // ②姓と名を連結し、姓名は全角へ、姓名（カナ）は半角に変換後全角に変換する（変換不可な文字を除外するため）
    // ③合計30文字までを切り出す
    contractInfo.contractorSeiMei = hanToZenOrEmpty(
      `${uqUser?.customer?.lastName?.trimEnd()}${NAME_FULL_SPACE}${uqUser?.customer?.firstName?.trimEnd()}`
    )
      .substring(0, 30)
      .trimEnd();
    contractInfo.contractorSeiMeiFull = `${uqUser?.customer?.lastName?.trimEnd()}${NAME_FULL_SPACE}${uqUser?.customer?.firstName?.trimEnd()}`;
    contractInfo.contractorSei = divideName(contractInfo.contractorSeiMei).lastName;
    contractInfo.contractorMei = divideName(contractInfo.contractorSeiMei).firstName;
    contractInfo.contractorSeiMeiKana = hanToZenOrEmpty(
      `${uqUser?.customer?.lastNameKana?.trimEnd()}${NAME_FULL_SPACE}${uqUser?.customer?.firstNameKana?.trimEnd()}`
    ).substring(0, 30);
    contractInfo.contractorSeiMeiKanaFull = `${uqUser?.customer?.lastNameKana?.trimEnd()}${NAME_FULL_SPACE}${uqUser?.customer?.firstNameKana?.trimEnd()}`;
    contractInfo.contractorSeiKana = divideName(contractInfo.contractorSeiMeiKana).lastName;
    contractInfo.contractorMeiKana = divideName(contractInfo.contractorSeiMeiKana).firstName;
    contractInfo.contractorGender = getSexType(String(uqUser?.customer?.sexType));
    contractInfo.contractorBirthday = uqUser?.customer?.birthday || '';
    contractInfo.userBirthday = uqUser?.user?.userBirthday || undefined;
    contractInfo.contractorTelephoneNumber = uqUser?.customer?.phoneNumber1 || '';
    contractInfo.contractorPost = uqUser?.customer?.postalCode || undefined;
    contractInfo.customerAddress = `${uqUser?.customer.customerAddress1 ?? ''}${
      uqUser?.customer.customerAddress2 ?? ''
    }${uqUser?.customer?.customerAddress3 ?? ''}`;
    contractInfo.telephoneNumber = uqUser?.line?.phoneNumber || '';
    contractInfo.mvnoCode = uqUser?.contract?.mvnoCompanyCode || undefined;
    contractInfo.usePersonSame = !uqUser?.user?.userType
      ? UsePersonSameValues.diffContract
      : UsePersonSameValues.sameContract;
    contractInfo.usePersonLastName = uqUser?.user?.userLastName || undefined;
    contractInfo.usePersonFirstName = uqUser?.user?.userFirstName || undefined;
    contractInfo.usePersonLastNameKana = uqUser?.user?.userLastNameKana || undefined;
    contractInfo.usePersonFirstNameKana = uqUser?.user?.userFirstNameKana || undefined;
    contractInfo.userBirthday = uqUser?.user?.userBirthday || undefined;
    contractInfo.contractorCategory = uqUser?.customer?.contractorCategory || undefined;
    contractInfo.billingPostalCode = uqUser?.billingAddress?.billingPostalCode || undefined;
  } else {
    // au・新UQユーザーの場合、Faceから顧客情報取得
    const user = accountInfo?.user;
    // ①不要な後ろ空白を削除
    // ②姓名は全角へ、姓名（カナ）は半角に変換後全角に変換する（変換不可な文字を除外するため）
    // ③合計30文字までを切り出す
    const contractorSeiMei = hanToZenOrEmpty(user?.kanjiFullNameSeiMei || '')
      .substring(0, 30)
      .trimEnd();
    const contractorSeiMeiKana = hanToZenOrEmpty(user?.kanaFullNameSeiMei || '')
      .substring(0, 30)
      .trimEnd();
    const userName = hanToZenOrEmpty(onlineShopSubscriberInfo?.userName || '')
      .substring(0, 30)
      .trimEnd();
    const userNameKana = hanToZenOrEmpty(onlineShopSubscriberInfo?.userNameKana || '')
      .substring(0, 30)
      .trimEnd();

    contractInfo.contractorSeiMei = contractorSeiMei;
    contractInfo.contractorSeiMeiFull = user?.kanjiFullNameSeiMei?.trimEnd() || '';
    contractInfo.contractorSei = divideName(contractorSeiMei).lastName;
    contractInfo.contractorMei = divideName(contractorSeiMei).firstName;
    contractInfo.contractorSeiMeiKana = contractorSeiMeiKana;
    contractInfo.contractorSeiMeiKanaFull = user?.kanaFullNameSeiMei?.trimEnd() || '';
    contractInfo.contractorSeiKana = divideName(contractorSeiMeiKana).lastName;
    contractInfo.contractorMeiKana = divideName(contractorSeiMeiKana).firstName;
    contractInfo.contractorGender = user?.gender || undefined;
    contractInfo.contractorBirthday = user?.birthday || '';
    contractInfo.contractorPost = user?.customerPostalNo || undefined;
    contractInfo.contractorTelephoneNumber = user?.customerPhoneNo || undefined;
    contractInfo.customerAddress = user?.customerAddress || undefined;
    contractInfo.billingAddress = onlineShopSubscriberInfo?.billingAddress || undefined;
    contractInfo.contractorEmailAddress = user?.customerEzmailAddress || undefined;
    contractInfo.usePersonLastName = divideName(userName).lastName;
    contractInfo.usePersonFirstName = divideName(userName).firstName;
    contractInfo.usePersonFullName = userName;
    contractInfo.usePersonLastNameKana = divideName(userNameKana).lastName;
    contractInfo.usePersonFirstNameKana = divideName(userNameKana).firstName;
    contractInfo.usePersonFullNameKana = userNameKana;
    contractInfo.userBirthday = onlineShopSubscriberInfo?.userBirthDate || undefined;
    contractInfo.companyType = onlineShopSubscriberInfo?.companyType || undefined;
    contractInfo.usePersonSame = UsePersonSameValues.sameContract;
    contractInfo.billingPostalCode = onlineShopSubscriberInfo?.billingPostalCode || undefined;
  }
  return contractInfo;
};
