import {
  getAreasSlice,
  getPrefecturesSlice,
  getDistrictsSlice,
  getInstorePickupAgenciesSlice
} from './agencyServiceSlice';

export { getAreas, getPrefectures, getDistricts, getInstorePickupAgencies } from './thunk';
export const { resetGetAreasServiceState } = getAreasSlice.actions;
export const { resetGetPrefecturesServiceState } = getPrefecturesSlice.actions;
export const { resetGetDistrictsServiceState } = getDistrictsSlice.actions;
export const { resetGetInstorePickupAgenciesServiceState } = getInstorePickupAgenciesSlice.actions;
