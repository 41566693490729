import React, { useCallback, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import { getIncludes } from '@/store/include/actions';
import { includeSelectors } from '@/store/include';
import { orderSelectors } from '@/store/order';
import { productApiSelectors } from '@/store/api/product';
import { getCustomerInformation } from '@/utils/CustomerInformation';
import { userApiSelectors } from '@/store/api/user';
import { getUndefValue } from '@/utils/functions';
import { Include } from './Include';

type Props = {
  id: string;
  required?: boolean;
  showLoading?: boolean;
};

export const IncludeContainerComponent: React.FC<Props> = ({ id, required = false, showLoading = false }) => {
  const dispatch = useAppDispatch();
  const include = useAppSelector(includeSelectors.stateOf(id));
  const goodsStockNo = useAppSelector(orderSelectors.getBuyTerminal)?.goodsStockNo;
  const contractType = useAppSelector(orderSelectors.getContractTypeGroup)?.contractType;
  const deviceCategory = useAppSelector(productApiSelectors.getDeviceCategoryParameter);
  const osType = useAppSelector(productApiSelectors.getOsType);
  const isContractTypeNewOrMnpOrNewOpen = useAppSelector(orderSelectors.isContractTypeNewOrMnpOrNewOpen); // 新規MNP or 新規open
  const isChangeContract = useAppSelector(orderSelectors.isContractTypeChange); // 機変
  const isMnpContract = useAppSelector(orderSelectors.isContractTypeMnp); // MNP
  const contractTypeGroup = useAppSelector(orderSelectors.getContractTypeGroup); // ContractTypeGroup取得
  const loginInfo = useAppSelector(orderSelectors.getLogin);
  const getAccountsApiResult = useAppSelector(userApiSelectors.getAccountsResult);
  const accountInfo = getAccountsApiResult.response;
  const telephoneNumber = isMnpContract
    ? contractTypeGroup?.otherCompanyMobilePhoneTelNumber
    : loginInfo?.telephoneNumber;
  const getContractorInfo = useAppSelector(orderSelectors.getContractorInfo);
  const getLineContractInfo = useAppSelector(orderSelectors.getLineContractInfo);

  const { contractorBirthday, userBirthday } = isContractTypeNewOrMnpOrNewOpen
    ? {
        contractorBirthday: getContractorInfo?.contractorBirthday,
        userBirthday: getLineContractInfo?.usePersonBirthday
      }
    : getCustomerInformation(isChangeContract, contractTypeGroup?.contractChangeJudgeFlg, accountInfo, telephoneNumber);

  const dispatchLoad = useCallback(() => {
    // すでにロード済・ロード中の場合が読み込まない。
    if (include?.loaded || include?.loading) {
      return;
    }
    if (id && goodsStockNo && deviceCategory) {
      dispatch(
        getIncludes({
          includeIDs: [id],
          productStockCode: goodsStockNo,
          contractType,
          deviceCategory,
          osType,
          contractorBirthday: getUndefValue(contractorBirthday),
          userBirthday
        })
      );
    } else if (
      [
        'COMMON_include007',
        'COMMON_include008',
        'COMMON_include011',
        'COMMON_include012',
        'COMMON_include013',
        'COMMON_include014'
      ].includes(id)
    ) {
      dispatch(
        getIncludes({
          includeIDs: [id]
        })
      );
    }
  }, [id, contractType, goodsStockNo, deviceCategory, osType, dispatch, include, contractorBirthday, userBirthday]);

  useEffect(dispatchLoad, [dispatchLoad]);

  return (
    <Include
      id={id}
      required={required}
      loaded={include?.loaded}
      loading={include?.loading}
      failed={include?.failed}
      loadHandler={dispatchLoad}
      content={include?.content}
      showLoading={showLoading}
    />
  );
};

export const IncludeContainer = React.memo<Props>(IncludeContainerComponent);
