import { order } from 'ols-order-webservice-typescript-client';
import type { RootState } from '@/store';
import { PurchasableTypes } from '@/typings/PurchasableType';
import { UseSims } from '@/typings/UseSim';
import { DeviceCategoryValue } from '@/typings/DeviceCategory';
import { OsType } from '@/typings/OsType';
import { UseSimTypes } from '@/typings/UseSimType';
import { ContractTypes } from '@/typings/ContractType';
import { orderSelectors } from '@/store/order';

/**
 * BFF-PRD-001: 販売商品情報取得API
 * @param state - ステート
 * @returns
 */
export const getSalesProductResult = (state: RootState): RootState['entity']['productService']['getSalesProduct'] =>
  state.entity.productService.getSalesProduct;

/**
 * BFF-PRD-002: 保証・サポートサービス取得API
 * @param state - ステート
 * @returns
 */
export const getCandidateAfterSaleServicesResult = (
  state: RootState
): RootState['entity']['productService']['getCandidateAfterSaleServices'] =>
  state.entity.productService.getCandidateAfterSaleServices;

/**
 * BFF-PRD-003: 端末割賦代金マスタAPI
 * @param state - ステート
 * @returns
 */
export const getInstallmentPriceByProductStockCodeResult = (
  state: RootState
): RootState['entity']['productService']['getInstallmentPriceByProductStockCode'] =>
  state.entity.productService.getInstallmentPriceByProductStockCode;

/**
 * BFF-PRD-004: アクセサリー一覧取得API
 * @param state - ステート
 * @returns
 */
export const getOptionalProductsResult = (
  state: RootState
): RootState['entity']['productService']['getOptionalProducts'] => state.entity.productService.getOptionalProducts;

/**
 * BFF-PRD-005: アクセサリー詳細情報取得API
 * @param state - ステート
 * @returns
 */
export const getOptionalProductDetailResult = (
  state: RootState
): RootState['entity']['productService']['getOptionalProductDetail'] =>
  state.entity.productService.getOptionalProductDetail;

/**
 * BFF-PRD-006: 機種変更区分取得API
 * @param state - ステート
 * @returns
 */
export const getDecisionDeviceChangeIncreaseResult = (
  state: RootState
): RootState['entity']['productService']['getDecisionDeviceChangeIncrease'] =>
  state.entity.productService.getDecisionDeviceChangeIncrease;

/**
 * BFF-PRD-007: AppleWatch用保証・サポートサービス取得API
 * @param state - ステート
 * @returns
 */
export const getCandidateAfterSaleServicesForAppleWatchResult = (
  state: RootState
): RootState['entity']['productService']['getCandidateAfterSaleServicesForAppleWatch'] =>
  state.entity.productService.getCandidateAfterSaleServicesForAppleWatch;

/**
 * BFF-PRD-008: お届け可能日取得API
 * @param state - ステート
 * @returns
 */
export const getDeliveryAvailableDateResult = (
  state: RootState
): RootState['entity']['productService']['getDeliveryAvailableDate'] =>
  state.entity.productService.getDeliveryAvailableDate;

/**
 * 販売商品情報取得API.税込み販売価格（新規）
 * @param state - ステート
 * @returns
 */
export const getSaleIncludedTaxPriceNew = (state: RootState): number | undefined =>
  state.entity.productService.getSalesProduct.response?.saleIncludedTaxPriceNew;

/**
 * 販売商品情報取得API.税込み販売価格（機種変）
 * @param state - ステート
 * @returns
 */
export const getSaleIncludedTaxPriceChangeDevice = (state: RootState): number | undefined =>
  state.entity.productService.getSalesProduct.response?.saleIncludedTaxPriceChangeDevice;

/**
 * 販売商品情報取得API.税込み販売価格（MNP）
 * @param state - ステート
 * @returns
 */
export const getSaleIncludedTaxPriceMnp = (state: RootState): number | undefined =>
  state.entity.productService.getSalesProduct.response?.saleIncludedTaxPriceMNP;

/**
 * 販売商品情報取得API.特定端末フラグ
 * @param state - ステート
 * @returns
 */
export const getSpecialPhone = (state: RootState): order.IsSpecialPhoneBffEnum | undefined =>
  state.entity.productService.getSalesProduct.response?.isSpecialPhone;

/**
 * 購入品種類がSIM かつeSIMのみかの判定
 * @param state - ステート
 * @returns
 */
export const isProductTypeSimAndESim = (state: RootState): boolean =>
  PurchasableTypes.SimCard === state.entity.productService.getSalesProduct.response?.productType &&
  UseSims.ESim === state.entity.productService.getSalesProduct.response?.simType;

/**
 * 機種判定
 * @param state - ステート
 * @returns
 */
export const getDeviceCategory = (state: RootState): DeviceCategoryValue | undefined => {
  if (!state.entity.productService.getSalesProduct.response) {
    return undefined;
  }
  const {
    singleDeviceType,
    winIsSeriesDeviceCategory,
    productType
  } = state.entity.productService.getSalesProduct.response;

  // FP判定
  if (winIsSeriesDeviceCategory === order.WinIsSeriesDeviceCategoryBffEnum.FP) {
    return order.DeviceCategoryBffEnum.FP;
  }
  // SP判定
  if (
    (winIsSeriesDeviceCategory === order.WinIsSeriesDeviceCategoryBffEnum.SP ||
      winIsSeriesDeviceCategory === order.WinIsSeriesDeviceCategoryBffEnum.I_PHONE) &&
    (productType === order.ProductTypeBffEnum.NEW || productType === order.ProductTypeBffEnum.OLD)
  ) {
    return order.DeviceCategoryBffEnum.SP;
  }
  // SIM判定
  if (productType === order.ProductTypeBffEnum.SIM) {
    return order.DeviceCategoryBffEnum.SIM;
  }
  // タブレット判定
  if (
    singleDeviceType === order.SingleDeviceTypeBffEnum.IPAD ||
    singleDeviceType === order.SingleDeviceTypeBffEnum.TABLET
  ) {
    return order.DeviceCategoryBffEnum.TABLET;
  }
  // ルーター判定
  if (singleDeviceType === order.SingleDeviceTypeBffEnum.ROUTER) {
    return order.DeviceCategoryBffEnum.ROUTER_5G;
  }
  return undefined;
};

/**
 * OS種類判定（includeコンテンツ取得APIを呼び出す為のパラメータ）
 * @param state - ステート
 * @returns
 */
export const getOsType = (state: RootState): OsType | undefined => {
  if (!state.entity.productService.getSalesProduct.response) {
    return undefined;
  }
  const {
    singleDeviceType,
    winIsSeriesDeviceCategory,
    productType
  } = state.entity.productService.getSalesProduct.response;

  // Android判定
  if (
    (winIsSeriesDeviceCategory === order.WinIsSeriesDeviceCategoryBffEnum.SP &&
      (productType === order.ProductTypeBffEnum.NEW || productType === order.ProductTypeBffEnum.OLD)) ||
    singleDeviceType === order.SingleDeviceTypeBffEnum.TABLET
  ) {
    return order.OsTypeBffEnum.ANDROID;
  }
  // Apple判定
  if (
    (winIsSeriesDeviceCategory === order.WinIsSeriesDeviceCategoryBffEnum.I_PHONE &&
      (productType === order.ProductTypeBffEnum.NEW || productType === order.ProductTypeBffEnum.OLD)) ||
    singleDeviceType === order.SingleDeviceTypeBffEnum.IPAD
  ) {
    return order.OsTypeBffEnum.APPLE;
  }
  return undefined;
};

/**
 * GTM商品種別取得(端末単体、商品種別と物販同時)
 * @param state - ステート
 * @returns
 */
export const getProductTypeGTM = (state: RootState): string => {
  let productType = '';

  const category = getDeviceCategory(state);
  const isOld = isProductTypeOld(state);
  const osType = getOsType(state);
  const simType = getSalesProductResult(state).response?.simType;

  // 端末単体
  if (state?.order?.estimate?.contractTypeGroup?.contractType === ContractTypes.NewOpen) {
    productType = '端末単体';
    return productType;
  }

  switch (category) {
    case order.DeviceCategoryBffEnum.FP: {
      productType = 'FP';
      break;
    }
    case order.DeviceCategoryBffEnum.SP: {
      if (osType === order.OsTypeBffEnum.ANDROID) {
        productType = isOld ? 'au_Certified-SP-Android' : 'SP-Android';
      } else if (osType === order.OsTypeBffEnum.APPLE) {
        productType = isOld ? 'au_Certified-SP-iPhone' : 'SP-iPhone';
      }

      break;
    }
    case order.DeviceCategoryBffEnum.SIM: {
      if (simType === UseSimTypes.ESim) {
        productType = 'eSIM';
      } else {
        productType = 'SIM';
      }
      break;
    }
    case order.DeviceCategoryBffEnum.TABLET: {
      if (osType === order.OsTypeBffEnum.ANDROID) {
        productType = 'Tab-Android';
      } else if (osType === order.OsTypeBffEnum.APPLE) {
        productType = 'Tab-iPad';
      }
      break;
    }
    case order.DeviceCategoryBffEnum.ROUTER_5G: {
      productType = 'Data';
      break;
    }
    default: {
      break;
    }
  }
  // GTM購入商品データ取得
  if (state.order) {
    const optionalList = orderSelectors.getEcommerceProductsGTM(state);
    if (optionalList.length > 0) {
      productType += ',物販同時';
      return productType;
    }
  }
  return productType;
};
/**
 * 単純機種取得
 * @param state - ステート
 * @returns
 */
export const getProductType = (state: RootState): string => {
  let productType = '';

  const category = getDeviceCategory(state);
  const isOld = isProductTypeOld(state);
  const osType = getOsType(state);
  const simType = getSalesProductResult(state).response?.simType;

  switch (category) {
    case order.DeviceCategoryBffEnum.FP: {
      productType = 'FP';
      break;
    }
    case order.DeviceCategoryBffEnum.SP: {
      if (osType === order.OsTypeBffEnum.ANDROID) {
        productType = isOld ? 'au_Certified-SP-Android' : 'SP-Android';
      } else if (osType === order.OsTypeBffEnum.APPLE) {
        productType = isOld ? 'au_Certified-SP-iPhone' : 'SP-iPhone';
      }

      break;
    }
    case order.DeviceCategoryBffEnum.SIM: {
      if (simType === UseSimTypes.ESim) {
        productType = 'eSIM';
      } else {
        productType = 'SIM';
      }
      break;
    }
    case order.DeviceCategoryBffEnum.TABLET: {
      if (osType === order.OsTypeBffEnum.ANDROID) {
        productType = 'Tab-Android';
      } else if (osType === order.OsTypeBffEnum.APPLE) {
        productType = 'Tab-iPad';
      }
      break;
    }
    case order.DeviceCategoryBffEnum.ROUTER_5G: {
      productType = 'Data';
      break;
    }
    default: {
      break;
    }
  }
  return productType;
};
/**
 * GTM物販の購入有無
 * @param optionCartSize - オプション品カート情報リストサイズ
 */
export const getProductUmuGTM = (optionCartSize: number) => (
  state: RootState
): {
  accessory: number;
  mobileWithNetwork: number;
  'sp-iPhone': number;
  'sp-Android': number;
  featurePhone: number;
  'tab-iPad': number;
  'tab-Android': number;
  'au_Certified-SP-iPhone': number;
  'au_Certified-SP-Android': number;
  sim: number;
  smartphone: number;
  'au_Certified-SP': number;
  tablet: number;
  data: number;
  eSIM: number;
  simTotal: number;
  mobileOnly: number;
} => {
  const result = {
    accessory: 0,
    mobileWithNetwork: 0,
    'sp-iPhone': 0,
    'sp-Android': 0,
    featurePhone: 0,
    'tab-iPad': 0,
    'tab-Android': 0,
    'au_Certified-SP-iPhone': 0,
    'au_Certified-SP-Android': 0,
    sim: 0,
    smartphone: 0,
    'au_Certified-SP': 0,
    tablet: 0,
    data: 0,
    eSIM: 0,
    simTotal: 0,
    mobileOnly: 0
  };
  if (state.order.estimate?.contractTypeGroup?.contractType === ContractTypes.NewOpen) {
    result.mobileOnly = 1;
    return result;
  }

  if (optionCartSize > 0) {
    result.accessory = 1;
  } else {
    result.accessory = 0;
  }

  const productType = getProductType(state);

  switch (productType) {
    case 'SP-iPhone': {
      result.mobileWithNetwork = 1;
      result['sp-iPhone'] = 1;
      result.smartphone = 1;
      break;
    }
    case 'SP-Android': {
      result.mobileWithNetwork = 1;
      result['sp-Android'] = 1;
      result.smartphone = 1;
      break;
    }
    case 'FP': {
      result.mobileWithNetwork = 1;
      result.featurePhone = 1;
      break;
    }
    case 'Tab-iPad': {
      result.mobileWithNetwork = 1;
      result['tab-iPad'] = 1;
      result.tablet = 1;
      break;
    }
    case 'Tab-Android': {
      result.mobileWithNetwork = 1;
      result['tab-Android'] = 1;
      result.tablet = 1;
      break;
    }
    case 'au_Certified-SP-iPhone': {
      result.mobileWithNetwork = 1;
      result['au_Certified-SP-iPhone'] = 1;
      result['au_Certified-SP'] = 1;
      break;
    }
    case 'au_Certified-SP-Android': {
      result.mobileWithNetwork = 1;
      result['au_Certified-SP-Android'] = 1;
      result['au_Certified-SP'] = 1;
      break;
    }
    case 'SIM': {
      result.accessory = 0;
      result.mobileWithNetwork = 1;
      result.sim = 1;
      result.simTotal = 1;
      break;
    }
    case 'eSIM': {
      result.accessory = 0;
      result.mobileWithNetwork = 1;
      result.eSIM = 1;
      result.simTotal = 1;
      break;
    }
    case 'Data': {
      result.mobileWithNetwork = 1;
      result.data = 1;
      break;
    }
    default:
      break;
  }

  return result;
};

/**
 * 中古端末判定
 * @param state - ステート
 * @returns
 */
export const isProductTypeOld = (state: RootState): boolean =>
  order.ProductTypeBffEnum.OLD === state.entity.productService.getSalesProduct.response?.productType;

/**
 * 機種判定（includeコンテンツ取得APIを呼び出す為のパラメータ）
 * @param state - ステート
 * @returns
 */
export const getDeviceCategoryParameter = (state: RootState): DeviceCategoryValue | undefined => {
  if (!state.entity.productService.getSalesProduct.response) {
    return undefined;
  }
  const {
    singleDeviceType,
    winIsSeriesDeviceCategory,
    productType,
    simType
  } = state.entity.productService.getSalesProduct.response;

  // FP判定
  if (winIsSeriesDeviceCategory === order.WinIsSeriesDeviceCategoryBffEnum.FP) {
    return order.DeviceCategoryBffEnum.FP;
  }
  // SP・SP中古判定
  if (
    winIsSeriesDeviceCategory === order.WinIsSeriesDeviceCategoryBffEnum.SP ||
    winIsSeriesDeviceCategory === order.WinIsSeriesDeviceCategoryBffEnum.I_PHONE
  ) {
    if (productType === order.ProductTypeBffEnum.NEW) {
      return order.DeviceCategoryBffEnum.SP;
    }
    if (productType === order.ProductTypeBffEnum.OLD) {
      return order.DeviceCategoryBffEnum.USED_SMART_PHONE;
    }
  }
  // SIM判定
  if (productType === order.ProductTypeBffEnum.SIM) {
    if (simType === UseSimTypes.ESim) {
      return order.DeviceCategoryBffEnum.E_SIM;
    }
    return order.DeviceCategoryBffEnum.SIM;
  }

  // タブレット判定
  if (
    singleDeviceType === order.SingleDeviceTypeBffEnum.IPAD ||
    singleDeviceType === order.SingleDeviceTypeBffEnum.TABLET
  ) {
    return order.DeviceCategoryBffEnum.TABLET;
  }
  // ルーター判定
  if (singleDeviceType === order.SingleDeviceTypeBffEnum.ROUTER) {
    return order.DeviceCategoryBffEnum.ROUTER_5G;
  }
  return undefined;
};
