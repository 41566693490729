import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import {
  DiscountCampaignApiService,
  GetAfterDayCampaignParameter,
  GetCouponListParameter,
  GetDiscountServiceParameter,
  GetAdditionalServiceParameter,
  GetFinancialDiscountParameter
} from '@/services/discountCampaignApiService';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { AreaCode } from '@/typings/AreaCode';
import { ContractType } from '@/typings/ContractType';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';
import { ContractBranchCode } from '@/typings/ContractBranchCode';
import { NetworkType } from '@/typings/NetworkType';
import { PaymentTimes } from '@/typings/PaymentTimes';

/**
 * BFF-DCP-001: 適用可能違約金即時ポイント一覧取得API
 */
export const getPenaltyImmediatePoints = createAsyncThunk<
  // Api正常時レスポンスの型
  order.InstantPointsGetResponseV1,
  // Apiリクエストパラメータの型
  {
    contractType: ContractType;
    mobileDeviceCode: string;
    networkType: NetworkType;
    paymentTimes: PaymentTimes;
    deferredPaymentInstallmentFlag: '0' | '1';
    contractBranchCode: ContractBranchCode | undefined;
    contractorBirthday?: string;
    callOptionCode?: string;
    annualContractCode?: string;
    dataPlanCode?: string;
    areaCodeCode?: AreaCode;
    agencyCode?: string;
    auContractId?: string;
    userBirthday?: string;
    mnpReservationNo?: string;
    mnpMnoNo?: string;
    kddioct?: string;
    mnpPhoneNo?: string;
    mvnoCode?: string;
    internetConnectServiceCode?: string;
    afterCareSaleServiceCode?: string;
    filteringServiceCode?: string;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'discountCampaignService/getPenaltyImmediatePoints',
  async (
    {
      contractType,
      mobileDeviceCode,
      networkType,
      paymentTimes,
      deferredPaymentInstallmentFlag,
      contractBranchCode,
      contractorBirthday,
      dataPlanCode,
      callOptionCode,
      annualContractCode,
      areaCodeCode,
      agencyCode,
      auContractId,
      userBirthday,
      mnpReservationNo,
      mnpMnoNo,
      kddioct,
      mnpPhoneNo,
      mvnoCode,
      internetConnectServiceCode,
      afterCareSaleServiceCode,
      filteringServiceCode
    },
    { rejectWithValue }
  ) => {
    const config = apiConfigProvider();
    try {
      return await DiscountCampaignApiService.getInstance(config).getPenaltyImmediatePoints(
        contractType,
        mobileDeviceCode,
        networkType,
        paymentTimes,
        deferredPaymentInstallmentFlag,
        contractBranchCode,
        contractorBirthday,
        callOptionCode,
        annualContractCode,
        dataPlanCode,
        areaCodeCode,
        agencyCode,
        auContractId,
        userBirthday,
        mnpReservationNo,
        mnpMnoNo,
        kddioct,
        mnpPhoneNo,
        mvnoCode,
        internetConnectServiceCode,
        afterCareSaleServiceCode,
        filteringServiceCode
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-DCP-001'));
    }
  }
);

/**
 * BFF-DCP-002: 割引施策一覧取得API
 */
export const getCouponList = createAsyncThunk<
  // Api正常時レスポンスの型
  order.CandidateCouponsGetResponseV1,
  // Apiリクエストパラメータの型
  GetCouponListParameter,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('discountCampaignService/getCouponList', async (getCouponListParameter, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await DiscountCampaignApiService.getInstance(config).getCouponList(getCouponListParameter);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-DCP-002'));
  }
});

/**
 * BFF-DCP-003: 割引サービス情報取得API
 */
export const getDiscountServices = createAsyncThunk<
  // Api正常時レスポンスの型
  order.DiscountServiceGetResponseV1,
  // Apiリクエストパラメータの型
  GetDiscountServiceParameter,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('discountCampaignService/getDiscountService', async (getDiscountServiceParameter, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await DiscountCampaignApiService.getInstance(config).getDiscountService(getDiscountServiceParameter);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-DCP-003'));
  }
});

/**
 * BFF-DCP-004: 後日キャンペーン適用情報取得API
 */
export const getAfterDayCampaigns = createAsyncThunk<
  // Api正常時レスポンスの型
  order.AfterDayCampaignGetResponseV1,
  // Apiリクエストパラメータの型
  GetAfterDayCampaignParameter,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('discountCampaignService/getAfterDayCampaign', async (getAfterDayCampaignParameter, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await DiscountCampaignApiService.getInstance(config).getAfterDayCampaign(getAfterDayCampaignParameter);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-DCP-004'));
  }
});

/**
 * BFF-DCP-005: 付加サービス情報取得API
 */
export const getAdditionalServices = createAsyncThunk<
  // Api正常時レスポンスの型
  order.AdditionalServiceGetResponseV1,
  // Apiリクエストパラメータの型
  GetAdditionalServiceParameter,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('discountCampaignService/getAdditionalService', async (getAdditionalServiceParameter, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await DiscountCampaignApiService.getInstance(config).getAdditionalService(getAdditionalServiceParameter);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-DCP-005'));
  }
});

/**
 * BFF-DCP-007: 金融割情報取得API
 */
export const getFinancialDiscounts = createAsyncThunk<
  // Api正常時レスポンスの型
  order.FinancialDiscountGetResponseV1,
  // Apiリクエストパラメータの型
  GetFinancialDiscountParameter,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('discountCampaignService/getFinancialDiscount', async (getFinancialDiscountParameter, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await DiscountCampaignApiService.getInstance(config).getFinancialDiscountService(
      getFinancialDiscountParameter
    );
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-DCP-007'));
  }
});
