import { order } from 'ols-order-webservice-typescript-client';
import { AreaCode } from '@/typings/AreaCode';
import { ProductType } from '@/typings/ProductType';
import { ContractBranchCode } from '@/typings/ContractBranchCode';
import { ContractType } from '@/typings/ContractType';
import { MobileDeviceJudgment } from '@/typings/MobileDeviceJudgment';
import { NetworkType } from '@/typings/NetworkType';
import { consoleLog } from '@/utils/functions';

const { ChargesPlanApi } = order;

export class DataPlanInformation {
  private static instance: DataPlanInformation;

  private api: order.ChargesPlanApi;

  private constructor(config: order.Configuration) {
    this.api = new ChargesPlanApi(config);
  }

  public static getInstance(config: order.Configuration): DataPlanInformation {
    if (!this.instance) {
      this.instance = new DataPlanInformation(config);
    }

    return this.instance;
  }

  /**
   * BFF-CHP-006: 選択可能データプラン・通話オプション情報取得API
   * @param contractType -契約種別
   * @param networkType -ネットワーク区分
   * @param productType -購入品種類
   * @param winIsSeries -WIN/ISシリーズ/iPhone
   * @param singleDeviceType -シングル端末種類
   * @param mobileDeviceCode -移動機単品商品コード
   * @param annualContractCode -年契約コード
   * @param eSimFlag -eSIMフラグ
   * @param densyu -電種
   * @param mobileDeviceJudgment -移動機種判定
   * @param productAttribute -商品属性
   * @param microSimType -microSIM区分
   * @param auPhoneUsePeriod -au電話機利用期間
   * @param auTelephoneNumber -au電話番号
   * @param contractorBirthday -契約者生年月日
   * @param userBirthday -利用者生年月日
   * @param mnpReservedNumber -MNP予約番号
   * @param mnpMnoNo -MNP移転元事業者コード
   * @param kddioct -KDDI/OCT区分
   * @param tOptionCodes -T系オプションコード
   * @param tabletSharePlanFlag -タブレットシェアプラン申込可能判定フラグ
   * @param romIcCardDeviceCategory -ROM機/ICカード
   * @param contractBranchCode -契約支社
   * @param areaCodeCode -契約都道府県
   * @returns
   */
  getCandidatePlanV1(
    contractType: ContractType,
    networkType: NetworkType,
    mobileDeviceCode: string,
    annualContractCode: order.AnnualContractCodeBffEnum,
    productType?: ProductType,
    winIsSeries?: order.WinIsSeriesDeviceCategoryBffEnum,
    singleDeviceType?: order.SingleDeviceTypeBffEnum,
    eSimFlag?: boolean | undefined,
    densyu?: string | undefined,
    mobileDeviceJudgment?: MobileDeviceJudgment,
    productAttribute?: string | undefined,
    microSimType?: order.MicroSimBffEnum | undefined,
    auPhoneUsePeriod?: string | undefined,
    auTelephoneNumber?: string | undefined,
    contractorBirthday?: string | undefined,
    userBirthday?: string | undefined,
    mnpReservedNumber?: string | undefined,
    mnpMnoNo?: string | undefined,
    kddioct?: string | undefined,
    tOptionCodes?: string[] | undefined,
    romIcCardDeviceCategory?: '8' | '5' | '6' | '7' | '9' | '10' | '12' | '13' | '14' | '15' | undefined,
    contractBranchCode?: ContractBranchCode,
    areaCodeCode?: AreaCode,
    mvnoCode?: string | undefined,
    agencyCode?: string | undefined,
    tabletSharePlanAvailable?: boolean,
    singleSimFlag?: '1',
    smartPhoneStartPlanAvailable?: boolean
  ): Promise<order.PlansResponseV1> {
    consoleLog(
      'BFF-CHP-006: 選択可能データプラン・通話オプション情報取得API call',
      contractType,
      networkType,
      productType,
      winIsSeries,
      singleDeviceType,
      mobileDeviceCode,
      annualContractCode,
      eSimFlag,
      densyu,
      mobileDeviceJudgment,
      productAttribute,
      microSimType,
      auPhoneUsePeriod,
      auTelephoneNumber,
      contractorBirthday,
      userBirthday,
      mnpReservedNumber,
      mnpMnoNo,
      kddioct,
      tOptionCodes,
      romIcCardDeviceCategory,
      contractBranchCode,
      areaCodeCode,
      mvnoCode,
      agencyCode,
      tabletSharePlanAvailable,
      singleSimFlag,
      smartPhoneStartPlanAvailable
    );
    return this.api
      .getCandidatePlanV1(
        contractType,
        networkType,
        annualContractCode,
        eSimFlag,
        densyu,
        mobileDeviceJudgment,
        productAttribute,
        microSimType,
        auPhoneUsePeriod,
        auTelephoneNumber,
        contractorBirthday,
        userBirthday,
        productType,
        winIsSeries,
        singleDeviceType,
        mobileDeviceCode,
        mnpReservedNumber,
        mnpMnoNo,
        kddioct,
        tOptionCodes,
        romIcCardDeviceCategory,
        contractBranchCode,
        areaCodeCode,
        mvnoCode,
        agencyCode,
        tabletSharePlanAvailable,
        singleSimFlag,
        smartPhoneStartPlanAvailable
      )
      .then((res) => res.data);
  }
}
