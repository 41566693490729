import { order } from 'ols-order-webservice-typescript-client';
import { NetworkType } from '@/typings/NetworkType';
import { consoleLog } from '@/utils/functions';

const { ChargesPlanApi } = order;

export class InternetConnectionServices {
  private static instance: InternetConnectionServices;

  private api: order.ChargesPlanApi;

  private constructor(config: order.Configuration) {
    this.api = new ChargesPlanApi(config);
  }

  public static getInstance(config: order.Configuration): InternetConnectionServices {
    if (!this.instance) {
      this.instance = new InternetConnectionServices(config);
    }

    return this.instance;
  }

  /**
   * BFF-CHP-004: 選択可能インターネット接続サービス情報取得API
   * @param networkType -ネットワーク区分
   * @param deviceIpadType -シングル端末種類
   * @param winIsSeriesDeviceCategory -WIN/ISシリーズ/iPhone
   * @returns
   */
  getInternetConnectionServices(
    networkType: NetworkType,
    deviceIpadType: '1' | '2' | '3' | '4',
    // fixme 【501】 winIsSeriesDeviceCategory をEnum化する
    winIsSeriesDeviceCategory: '1' | '2' | '3' | '4' | '5'
  ): Promise<order.InternetConnectionServiceGetResponseV1> {
    consoleLog(
      'BFF-CHP-004: 選択可能インターネット接続サービス情報取得API call',
      networkType,
      deviceIpadType,
      winIsSeriesDeviceCategory
    );
    return this.api
      .getInternetConnectionServicesV1(networkType, deviceIpadType, winIsSeriesDeviceCategory)
      .then((res) => res.data);
  }
}
