import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContractType } from '@/typings/ContractType';
import { DeliveryDate } from '@/typings/DeliveryDateType';
import { ReceivingPlaceCode } from '@/typings/ReceivingPlaceCode';
import { CartInfo } from '@/typings/CartInfo';
import { UqUserStatusType } from '@/typings/UqStatus';
import { ImportantMatterInfo } from '@/typings/ImportantMatterInfo';
import { FileLabelType } from '@/typings/IdentificationFileLabelType';
import { NetworkType } from '@/typings/NetworkType';
import { ReturnEstimateType } from '@/typings/returnEstimateType';
import { ApplyType } from '@/typings/ApplyType';
import { CompanyType } from '@/typings/CompanyType';
import { ProgramPrivilegeUseValue } from '@/typings/ProgramPrivilegeUseValue';
import { OtherCompanyInvoiceInfo } from '@/typings/OtherCompanyInvoiceInfoUpload';
import { FinancialInstitutionUsed } from '@/typings/FinancialInstitutionUsed';
import { MethodOfPayment } from '@/typings/MethodOfPayment';
import { ProductPaymentMethod } from '@/typings/ProductPaymentMethod';

/**
 * 注文
 */
export type OrderState = {
  /**
   * ログイン
   */
  login?: Login;

  /**
   * 所有端末
   */
  ownedTerminal?: OwnedTerminal;

  /**
   * 見積
   */
  estimate?: Estimate;

  /**
   * 契約
   */
  contract?: Contract;

  /**
   * 決済
   */
  payment?: Payment;

  /**
   * 注文付加情報
   */
  orderAdditionInfo?: OrderAdditionInfo;

  /** 以下、state項目定義（Sheet1_グループ化）に記載がなく、実装上定義・画面で利用されているもの */

  /**
   * 注文番号
   */
  ordNo?: string;

  /**
   * 接続元端末種別
   */
  accessDeviceType?: string;

  /**
   * 遷移元ボタン種別
   */
  auIdBtnType?: string;

  /**
   * メールアドレス
   */
  mailAddressValue?: string;

  /**
   * 事務手数料有無（ハッシュ値）
   */
  hashAdministrationFee?: string;

  /**
   * 事業者選択情報
   */
  mnpOnestopInfo?: MnpOnestopInfo;

  /**
   * 見積保存時メールアドレス
   */
  estimateMailAddress?: string;

  /**
   * 見積保存ステータス
   */
  estimateSaveStatus?: string;

  /**
   * OpenAuId認証情報
   */
  authOpenAuIdInfo?: AuthOpenAuIdInfo;
};

export type Login = {
  /**
   * 選択電話番号
   */
  telephoneNumber?: string;

  /**
   * 回線有無フラグ
   */
  telephonePresence?: boolean;
};

export type MnpOnestopInfo = {
  /**
   * MNP予約番号有無ラジオボタン
   */
  mnpReserveUmu?: string;

  /**
   * 事業者ラジオボタン
   */
  mnpOnestopSelected?: string;

  /**
   * MNP（OLS選択）移転元事業者コード
   */
  mnpOnestopOperatorNo?: string;

  /**
   * MNP移転元事業者コード
   */
  mnpMnoNo?: string;

  /**
   * KDDI/OCT区分
   */
  kddioct?: string;

  /**
   * MNP予約番号参照可能日時
   */
  mnpValidDate?: string;

  /**
   * MNP予約番号
   */
  mnpReserveNumber?: string;

  /**
   * 他社利用携帯番号
   */
  otherCompanyMobilePhoneTelNumber?: string;

  /**
   * MNP有効期限
   */
  mnpExpirationDate?: string;
};
export type AuthOpenAuIdInfo = {
  /**
   * AuthOpenAuId認証結果
   */
  authOpenAuIdRes?: string;
};

export type OwnedTerminal = {
  /**
   * ご契約中の故障紛失サポート with AppleCare Services(級AppleCare＋&au端末サポート)
   */
  appleCareList?: Record<string, string>;

  /**
   * ご契約中の保証・サポートサービス
   */
  supportCancelType?: string;

  /**
   * ご契約中の保証・サポートサービス
   */
  supportCancelDeviceCode?: string;

  /**
   * アップグレードプログラムの特典利用
   */
  upgradeProgramPrivilegeUse?: string;

  /**
   * かえトクプログラム/スマホトクするプログラムの特典利用
   */
  changeProgramPrivilegeUse?: string;

  /**
   * オープンかえトク/オープンスマトク特典利用対象端末
   */
  openChangeProgramPrivilegeUseDevice?: string;

  /**
   * オープンかえトク/オープンスマトク特典利用
   */
  openChangeProgramPrivilegeUse?: string;

  /**
   * 残価型割賦サービス種別（オープン）
   */
  openResidualValueServiceType?: string;

  /**
   * * オープンアップグレード特典利用
   */
  openUpgradeProgramPrivilegeUse?: ProgramPrivilegeUseValue;

  /**
   * オープンアップグレード特典利用対象端末
   */
  openUpgradeProgramPrivilegeUseDevice?: string;

  /**
   * 回収キット送付先
   */
  recoverKit?: ReceivingPlaceCode;

  /**
   * 下取りプログラム
   */
  tradeInProgram?: string;

  /**
   * 最新移動機のAppleCareの解約区分
   */
  newAppleCareCancelType?: string;

  /**
   * 持ち込み故障サポート
   */
  simOnlyWarrantyService?: string;
};

export type ContractTypeGroup = {
  /**
   * 契約種別
   */
  contractType?: ContractType;

  /**
   * 申込区分
   */
  applyKbn?: ApplyType;

  /**
   * 見積復帰区分
   */
  returnEstimateKbn?: ReturnEstimateType;

  /**
   * MNP予約番号
   */
  mnpReserveNumber?: string;

  /**
   * 他社利用携帯番号
   */
  otherCompanyMobilePhoneTelNumber?: string;

  /**
   * MNP有効期限(yyyyMMdd形式)
   */
  mnpExpirationDate?: string;

  /**
   * OCTユーザ判定区分
   */
  octUserType?: CompanyType;

  /**
   * UQ新旧ステータス
   */
  uqUserStatus?: UqUserStatusType;

  /**
   * esimの回線ありフラグ
   */
  esimLineInfoFlag?: string;

  /**
   * 契約変更判定フラグ
   */
  contractChangeJudgeFlg?: boolean;

  /**
   * クイックフローフラグ
   */
  quickFlag?: boolean;
};

export type BuyTerminal = {
  /**
   * 商品コード
   */
  goodsStockNo?: string;

  /**
   * ネットワーク区分
   */
  lineType?: NetworkType;

  /**
   * ご利用SIM
   */
  useSim?: string;

  /**
   * SIMロック解除(ご希望の方)
   */
  simLockFree?: string;
};

export type Accessory = {
  /**
   * 無料同梱品カート情報
   */
  freeBundledGoodsCartInfo?: CartInfo;

  /**
   * オプション品カート情報リスト
   */
  optionalGoodsCartInfoList?: Record<string, CartInfo>;
};

export type LineContract = {
  /**
   * 料金プラン
   */
  dataPlanCode?: string;

  /**
   * UQ料金プラン(表示用)
   */
  uqDataPlanCode?: string;

  /**
   * 増量オプション
   */
  increasedOptionCode?: string;

  /**
   * 通話オプション
   */
  callOptionCode?: string;

  /**
   * インターネット接続サービス
   */
  internetConnectionServiceCode?: string;

  /**
   * 他社請求書情報アップロード対象フラグ
   */
  otherCompanyInvoiceInfoUpload?: OtherCompanyInvoiceInfo;
};

export type LineContractDiscount = {
  /**
   * 2年契約の選択
   */
  annualContractCode?: string;

  /**
   * au PAYカードお支払い割
   */
  auPayCardDiscount?: string;

  /**
   * 自宅セット割
   */
  homeDiscount?: string;

  /**
   * 自宅セット割税込み金額
   */
  homeDiscountTaxPrice?: number;

  /**
   * 家族セット割
   */
  familyDiscountPlus?: string;

  /**
   * 家族セット割税込み金額
   */
  familyDiscountPlusTaxPrice?: number;

  /**
   * 付加サービス
   */
  additionalService?: additionalService;

  /**
   * Pontaポイント還元特典
   */
  additionalServicePoint?: additionalServicePoint;

  /**
   * au PAY残高還元特典
   */
  additionalServiceRefund?: additionalServiceRefund;

  /**
   * au PAY カード特典
   */
  auPayCardHoldDiscountAvailable?: string;

  /**
   * auじぶん銀行特典
   */
  jibunBankHoldDiscountAvailable?: string;

  /**
   * 通信料金支払い特典
   */
  paymentDiscountAvailable?: string;
};

export type Option = {
  /**
   * フィルタリングサービス
   */
  filteringService?: string;

  /**
   * auスマートパス
   */
  auSmartPassOptionCode?: string;

  /**
   * auウェルネス
   */
  auWellnessOptionCode?: string;

  /**
   * Apple Music
   */
  appleMusicOptionCode?: string;

  /**
   * YouTube Premium
   */
  youtubePremiumOptionCode?: string;

  /**
   * au自転車サポート
   */
  auBicycleSupportOptionCode?: string;
};

export type AfterCareService = {
  /**
   * 保証・サポートサービス
   */
  afterCareSaleServiceCode?: string;

  /**
   * 保証・サポートサービス(AppleWatch)
   */
  afterCareSaleServiceCodeForAppleWatch?: string;
};

export type TerminalBuyMethod = {
  /**
   * スマホトクするプログラム申込状況
   */
  changePrivilege?: string;

  /**
   * 機種代金のお支払方法
   */
  paymentMethods?: string;

  /**
   * 再割賦可否
   */
  reInstallmentUnavailability?: boolean;
};

export type AccessoryBuyMethod = {
  /**
   * アクセサリー(分割可)のお支払方法
   */
  accessoryPaymentMethods?: string;

  /**
   * アクセサリー(一括のみ)のお支払方法
   */
  accessoryPaymentMethodsBulk?: string;
};

export type Discount = {
  /**
   * キャンペーン・割引特典
   */
  instantPointSisakuNo?: string;

  /**
   * 利用ポイント数
   */
  usePoint?: number;

  /**
   * 通常クーポン番号
   */
  useCoupon?: string;

  /** MyAuクーポン番号 */
  useMyAuCoupon?: string;

  /**
   * キャッシュバック施策集約番号
   */
  wltcbMeasureNo?: string;

  /**
   * 毎月割施策番号
   */
  monthlyDiscountMeasureNo?: string;

  /**
   * クーポン施策番号
   */
  couponMeasureNo?: string;

  /**
   * 後日キャンペーン
   */
  afterDayCampaign?: Array<string>;
};

export type Estimate = {
  /**
   * 契約種別
   */
  contractTypeGroup?: ContractTypeGroup;

  /**
   * 購入端末
   */
  buyTerminal?: BuyTerminal;

  /**
   * アクセサリー
   */
  accessory?: Accessory;

  /**
   * 回線契約
   */
  lineContract?: LineContract;

  /**
   * 回線契約値引
   */
  lineContractDiscount?: LineContractDiscount;

  /**
   * オプション
   */
  option?: Option;

  /**
   * 保証サービス
   */
  afterCareService?: AfterCareService;

  /**
   * 端末購入方法
   */
  terminalBuyMethod?: TerminalBuyMethod;

  /**
   * アクセサリー購入方法
   */
  accessoryBuyMethod?: AccessoryBuyMethod;

  /**
   * 割引
   */
  discount?: Discount;
};

export type ContractorInfo = {
  /**
   * 契約者名 姓
   */
  contractorLastName?: string;

  /**
   * 契約者名 名
   */
  contractorFirstName?: string;

  /**
   * 契約者名 姓(カナ)
   */
  contractorLastNameKana?: string;

  /**
   * 契約者名 名(カナ)
   */
  contractorFirstNameKana?: string;

  /**
   * 契約者メールアドレス
   */
  contractorEmailAddress?: string;

  /**
   * 契約者生年月日
   */
  contractorBirthday?: string;

  /**
   * 契約者性別
   */
  contractorGender?: string;

  /**
   * 契約者郵便番号
   */
  contractorPost?: string;

  /**
   * 契約者都道府県
   */
  contractorPrefecture?: string;

  /**
   * 契約者市区郡
   */
  contractorCity?: string;

  /**
   * 契約者町村
   */
  contractorTown?: string;

  /**
   * 契約者番地
   */
  contractorBlock?: string;

  /**
   * 契約者電話番号
   */
  contractorTelephoneNumber?: string;
};

export type Identification = {
  /**
   * ご本人さま確認方法
   */
  identityVerificationMethodByPhotoOrUpload?: string;

  /**
   * 本人確認書類
   */
  identityVerificationDocuments?: string;

  /**
   * 本人確認書類ファイルリスト
   */
  identificationImageList?: Record<string, string>;

  /**
   * 裏面オブジェクト
   */
  backSideImageListArray?: Array<backSideImageListArray>;

  /**
   * 補助書類オブジェクト
   */
  auxiliaryDocumentListArray?: Record<string, Record<string, string>>;

  /**
   * 本人確認書類ファイルリストAPIパラメータ用
   */
  identificationImageListArray?: Array<identificationImageListArr>;

  /**
   * 確認書類番号(運転免許証番号)
   */
  identityVerificationDocumentsNumberUM?: string;

  /**
   * 確認書類番号(健康保険証番号)
   */
  identityVerificationDocumentsNumberKH?: string;
  /**
   * 本人確認書類種別
   */
  identificationType?: string;

  /**
   * 本人確認書類の種類毎のファイルリスト
   */
  identityVerificationDocumentsImageList?: Record<string, Record<string, string>>;

  /**
   * ekycリクエストパラメータ
   */
  ekycRequestParam?: EkycRequestParams;
};

export type ContractAdditionInfo = {
  /**
   * 契約書類郵送フラグ
   */
  enabledDeliveryDocument?: boolean;

  /**
   * 重要事項説明リスト
   */
  importantMatterInfo?: Partial<ImportantMatterInfo>;

  /**
   * 同意事項(重要事項説明)
   */
  importantInfoAgree?: boolean;

  /**
   * 同意事項(信用情報機関への基礎特定信用制度の通知)
   */
  creditFacilityNoticeAgree?: boolean;

  /**
   * 同意事項(割賦販売法第4条の規定に基づく書面のEメール通知)
   */
  instSalelawMailSendingAgree?: boolean;

  /**
   * 同意事項(強制開通の同意)
   */
  forcedOpeningAgree?: boolean;

  /**
   * auPAYカードの申込意思
   */
  auPayCardApplyStatus?: string;

  /**
   * じぶん銀行の申込意思
   */
  jibunBankApplyStatus?: string;

  /**
   * お支払方法の変更意思
   */
  paymentMethodChangeApplyStatus?: string;
};

export type Contract = {
  /**
   * 契約者情報
   */
  contractorInfo?: ContractorInfo;

  /**
   * 回線契約情報
   */
  lineContractInfo?: LineContractInfo;

  /**
   * 本人確認
   */
  identification?: Identification;

  /**
   * 契約付加情報
   */
  contractAdditionInfo?: ContractAdditionInfo;
};

export type Payment = {
  /**
   * 3Dセキュア認証有無
   */
  required3DSecure?: boolean;

  /**
   * 本人認証パスワード入力画面URL
   */
  acsUrl?: string;

  /**
   * 本人認証要求電文
   */
  identityVerificationRequest?: string;

  /**
   * 取引ID
   */
  transactionId?: string;

  /**
   * 3Dセキュア認証代替キー
   */
  alternateKey?: string;

  /**
   * 注文番号
   */
  orderNo?: string;

  /**
   * 申込書番号
   */
  applicationNo?: string;

  /**
   * クイックフローフラグ
   */
  quickFlag?: boolean;

  /**
   * クイックフロー申込タイトル
   */
  quickApplicationTitle?: string;

  /**
   * クイックフロー申込区分名称
   */
  quickApplicationTypeName?: string;

  /**
   * 注文日時
   */
  orderDateTime?: string;

  /**
   * 申込区分名称
   */
  applicationTypeName?: string;

  /**
   * 加盟店ID
   */
  memberShopId?: string;

  /**
   * アクワイアラ会社コード
   */
  acquirerCompCode?: string;

  /**
   * カード識別コード
   */
  cardDiscriminationCode?: string;

  /**
   * トークン化会員番号
   */
  tokenizedCardNo?: string;

  /**
   * 有効期限
   */
  expirationDate?: string;

  /**
   * 処理結果区分
   */
  resultKbn?: string;

  /**
   * 受付番号
   */
  receptionNo?: string;

  /**
   * システムID
   */
  systemId?: string;

  /**
   * 処理要求番号
   */
  processingRequestNo?: string;

  /**
   * 端末番号
   */
  clientNo?: string;

  /**
   * メッセージコード
   * エラー時のみ返却される。
   */
  messageCode?: string;

  /**
   * カード名義人
   */
  cardHolderValue?: string;

  /**
   * セキュリティコード
   */
  securityCodeValue?: string;
  /**
   * snareから返却されたdeviceId
   */
  deviceId?: string;

  /**
   * 会社コード
   */
  financialInstitutionUsed?: FinancialInstitutionUsed;

  /**
   * 月々のお支払い方法
   */
  methodOfPayments?: MethodOfPayment;

  /**
   * 商品のお支払い方法
   */
  productPaymentMethod?: ProductPaymentMethod;

  /**
   * auPAYカードの申込意思
   */
  auPayCardApplyStatus?: string;

  /**
   * じぶん銀行の申込意思
   */
  jibunBankApplyStatus?: string;

  /**
   * お支払方法の変更意思
   */
  paymentMethodChangeApplyStatus?: string;

  /**
   * auPay残高支払い（利用可能残高）
   */
  auPayCardBalance?: number;

  /**
   * auかんたん決済（通信料合算支払い）（利用可能残高）
   */
  auEasyPaymentBalance?: number;
};

export type OpeningHour = {
  /**
   * 営業時間曜日情報
   */
  dayType?: string;

  /**
   * 開店時間 HH:mm形式
   */
  openedAt?: string;

  /**
   * 閉店時間 HH:mm形式
   */
  closedAt?: string;
};

export type ReceivingShopInformation = {
  /**
   * 代理店コード
   */
  agencyCode?: string;

  /**
   * 店舗名
   */
  agencyName?: string;

  /**
   * 都道府県名
   */
  prefectureName?: string;

  /**
   * 表示用住所・地区名
   */
  displayCityName?: string;

  /**
   * 表示用住所・住所番地号
   */
  addressHouseNo?: string;

  /**
   * 営業時間情報
   */
  openingHourList?: OpeningHour[];

  /**
   * 定休日
   */
  fixedHoliday?: string;

  /**
   * 下取り受付
   */
  withTradeInCounterFunc?: boolean;

  /**
   * auショップURL
   */
  auShopUrl?: string;
};

export type OrderAdditionInfo = {
  /**
   * 受取場所
   */
  receivingPlace?: ReceivingPlaceCode;

  /**
   * 受取場所住所（表示用）
   */
  receivingPlaceAddress?: string;

  /**
   * 受取店舗情報
   */
  receivingShopInformation?: ReceivingShopInformation;

  /**
   * お届け日区分
   */
  deliveryDate?: DeliveryDate;
  /**
   * リードタイム郵便番号
   */
  leadTimePostalCode?: string;

  /**
   * お届け日日付指定
   */
  specifyDeliveryDate?: string;

  /**
   * お届け日時間指定
   */
  specifyDeliveryTime?: string;

  /**
   * お届け日日付（表示用）
   */
  selectedSpecifyDeliveryDate?: string;

  /**
   * お届け日時間（表示用）
   */
  selectedSpecifyDeliveryTime?: string;

  /**
   * 適用リードタイム
   */
  appliedLeadTime?: string;

  /**
   * ご注文内容確認用パスワード
   */
  passwordForOrderContentConfirmation?: string;

  /**
   * ご注文内容確認用パスワード(確認用)
   */
  rePasswordForOrderContentConfirmation?: string;

  /**
   * アフィリエイトコード1
   */
  affiliateCode1?: string;

  /**
   * アフィリエイトコード2
   */
  affiliateCode2?: string;

  /**
   * リクエストパラメータ 代理店コード
   */
  agencyCode?: string;
  /**
   * トスアップ受付店コード
   */
  tossUpAgencyCode?: string;
  /**
   * トスアップ担当者ID
   */
  tossUpStaffCode?: string;
  /**
   * トスアップ受付店名称
   */
  tossUpShopName?: string;
};

export type LineContractInfo = {
  /**
   * 当社からのご連絡先
   */
  contactSame?: string;

  /**
   * 勤務先名
   */
  businessName?: string;

  /**
   * 勤務先名(カナ)
   */
  businessNameKana?: string;

  /**
   * 勤務先電話番号
   */
  businessTelephoneNumber?: string;

  /**
   * 利用者の選択
   */
  usePersonSame?: string;

  /**
   * 利用者名 姓
   */
  usePersonLastName?: string;

  /**
   * 利用者名 名
   */
  usePersonFirstName?: string;

  /**
   * 利用者名 姓(カナ)
   */
  usePersonLastNameKana?: string;

  /**
   * 利用者名 名(カナ)
   */
  usePersonFirstNameKana?: string;

  /**
   * 利用者生年月日
   */
  usePersonBirthday?: string;

  /**
   * 利用者確認書類
   */
  usePersonConfirmationImage?: string;

  /**
   * 暗証番号
   */
  pinCode?: string;

  /**
   * 請求書送付先
   */
  billingAddressSame?: string;

  /**
   * 請求書送付先勤務先名
   */
  billingBusinessName?: string;

  /**
   * 請求書送付先部署名
   */
  billingBusinessDivisionName?: string;

  /**
   * 請求書送付先郵便番号
   */
  billingPost?: string;

  /**
   * 請求書送付先都道府県
   */
  billingPrefecture?: string;

  /**
   * 請求書送付先市区郡
   */
  billingCity?: string;

  /**
   * 請求書送付先町村
   */
  billingTown?: string;

  /**
   * 請求書送付先番地
   */
  billingBlock?: string;

  /**
   * 請求書送付先勤務先電話番号
   */
  billingTelephone?: string;
};

export type identificationImageListArr = {
  /**
   * 本人確認対象種別
   */
  uploadedIdentificationTargetType?: string;

  /**
   * 本人確認書類種別
   */
  uploadedIdentificationType?: string;
  /**
   * 本人確認画像No
   */
  uploadedImageNo?: string;
  /**
   * 補助書類種別
   */
  auxiliaryDocumentType?: string;
};

export type backSideImageListArray = {
  /**
   * 裏面質問
   */
  backSideImageQuestion?: string;
  /**
   * 裏面画像名
   */
  backSideImageName?: string;
};

export type EkycRequestParams = {
  /**
   * ekycクエリパラメータ
   */
  ekycRes?: string;

  /**
   * bidクエリパラメータ
   */
  bid?: string;
  /**
   * eKYC申請番号
   */
  applicantId?: string;
};

export type additionalService = {
  /**
   * 付加サービスコード
   */
  code?: string[];
};

export type additionalServicePoint = {
  /**
   * Pontaポイント還元特典
   */
  code?: string[];
};

export type additionalServiceRefund = {
  /**
   * au PAY残高還元特典
   */
  code?: string[];
};

export const initialState: OrderState = {};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // 汎用
    setOrderValue: (state: OrderState, action: PayloadAction<Partial<OrderState>>) => ({
      ...state,
      ...action.payload
    }),

    // ログイン(第1階層用)
    setLogin: (state: OrderState, action: PayloadAction<Partial<Login>>) => ({
      ...state,
      login: {
        ...state.login,
        ...action.payload
      }
    }),

    // OpenAuID認証情報(第1階層用)
    setAuthOpenAuIdInfo: (state: OrderState, action: PayloadAction<Partial<AuthOpenAuIdInfo>>) => ({
      ...state,
      authOpenAuIdInfo: {
        ...state.authOpenAuIdInfo,
        ...action.payload
      }
    }),

    // 事業者選択情報(第1階層用)
    setMnpOnestopInfo: (state: OrderState, action: PayloadAction<Partial<MnpOnestopInfo>>) => ({
      ...state,
      mnpOnestopInfo: {
        ...state.mnpOnestopInfo,
        ...action.payload
      }
    }),

    // 契約(第1階層用)
    setOwnedTerminal: (state: OrderState, action: PayloadAction<Partial<OwnedTerminal>>) => ({
      ...state,
      ownedTerminal: {
        ...state.ownedTerminal,
        ...action.payload
      }
    }),

    // 見積(第1階層用)
    setEstimate: (state: OrderState, action: PayloadAction<Partial<Estimate>>) => ({
      ...state,
      estimate: {
        ...state.estimate,
        ...action.payload
      }
    }),

    // 契約(第1階層用)
    setContract: (state: OrderState, action: PayloadAction<Partial<Contract>>) => ({
      ...state,
      contract: {
        ...state.contract,
        ...action.payload
      }
    }),

    // 決済(第1階層用)
    setPayment: (state: OrderState, action: PayloadAction<Partial<Payment>>) => ({
      ...state,
      payment: {
        ...state.payment,
        ...action.payload
      }
    }),

    // 注文付加情報(第1階層用)
    setOrderAdditionInfo: (state: OrderState, action: PayloadAction<Partial<OrderAdditionInfo>>) => ({
      ...state,
      orderAdditionInfo: {
        ...state.orderAdditionInfo,
        ...action.payload
      }
    }),

    // 契約種別(第2階層用)
    setContractTypeGroup: (state: OrderState, action: PayloadAction<Partial<ContractTypeGroup>>) => ({
      ...state,
      estimate: {
        ...state.estimate,
        contractTypeGroup: {
          ...state.estimate?.contractTypeGroup,
          ...action.payload
        }
      }
    }),

    // 購入端末(第2階層用)
    setBuyTerminal: (state: OrderState, action: PayloadAction<Partial<BuyTerminal>>) => ({
      ...state,
      estimate: {
        ...state.estimate,
        buyTerminal: {
          ...state.estimate?.buyTerminal,
          ...action.payload
        }
      }
    }),

    // アクセサリー(第2階層用)
    setAccessory: (state: OrderState, action: PayloadAction<Partial<Accessory>>) => ({
      ...state,
      estimate: {
        ...state.estimate,
        accessory: {
          ...state.estimate?.accessory,
          ...action.payload
        }
      }
    }),

    // 回線契約(第2階層用)
    setLineContract: (state: OrderState, action: PayloadAction<Partial<LineContract>>) => ({
      ...state,
      estimate: {
        ...state.estimate,
        lineContract: {
          ...state.estimate?.lineContract,
          ...action.payload
        }
      }
    }),

    // 回線契約値引(第2階層用)
    setLineContractDiscount: (state: OrderState, action: PayloadAction<Partial<LineContractDiscount>>) => ({
      ...state,
      estimate: {
        ...state.estimate,
        lineContractDiscount: {
          ...state.estimate?.lineContractDiscount,
          ...action.payload
        }
      }
    }),

    // オプション(第2階層用)
    setOption: (state: OrderState, action: PayloadAction<Partial<Option>>) => ({
      ...state,
      estimate: {
        ...state.estimate,
        option: {
          ...state.estimate?.option,
          ...action.payload
        }
      }
    }),

    // 保証サービス(第2階層用)
    setAfterCareService: (state: OrderState, action: PayloadAction<Partial<AfterCareService>>) => ({
      ...state,
      estimate: {
        ...state.estimate,
        afterCareService: {
          ...state.estimate?.afterCareService,
          ...action.payload
        }
      }
    }),

    // 端末購入方法(第2階層用)
    setTerminalBuyMethod: (state: OrderState, action: PayloadAction<Partial<TerminalBuyMethod>>) => ({
      ...state,
      estimate: {
        ...state.estimate,
        terminalBuyMethod: {
          ...state.estimate?.terminalBuyMethod,
          ...action.payload
        }
      }
    }),

    // アクセサリー購入方法(第2階層用)
    setAccessoryBuyMethod: (state: OrderState, action: PayloadAction<Partial<AccessoryBuyMethod>>) => ({
      ...state,
      estimate: {
        ...state.estimate,
        accessoryBuyMethod: {
          ...state.estimate?.accessoryBuyMethod,
          ...action.payload
        }
      }
    }),

    // 割引(第2階層用)
    setDiscount: (state: OrderState, action: PayloadAction<Partial<Discount>>) => ({
      ...state,
      estimate: {
        ...state.estimate,
        discount: {
          ...state.estimate?.discount,
          ...action.payload
        }
      }
    }),

    // 契約者情報(第2階層用)
    setContractorInfo: (state: OrderState, action: PayloadAction<Partial<ContractorInfo>>) => ({
      ...state,
      contract: {
        ...state.contract,
        contractorInfo: {
          ...state.contract?.contractorInfo,
          ...action.payload
        }
      }
    }),

    // 回線契約情報(第2階層用)
    setLineContractInfo: (state: OrderState, action: PayloadAction<Partial<LineContractInfo>>) => ({
      ...state,
      contract: {
        ...state.contract,
        lineContractInfo: {
          ...state.contract?.lineContractInfo,
          ...action.payload
        }
      }
    }),

    // 本人確認(第2階層用)
    setIdentification: (state: OrderState, action: PayloadAction<Partial<Identification>>) => ({
      ...state,
      contract: {
        ...state.contract,
        identification: {
          ...state.contract?.identification,
          ...action.payload
        }
      }
    }),

    // ekycリクエストパラメータ(第3階層用)
    setEkycRequestParams: (state: OrderState, action: PayloadAction<Partial<EkycRequestParams>>) => ({
      ...state,
      contract: {
        ...state.contract,
        identification: {
          ...state.contract?.identification,
          ekycRequestParam: {
            ...state.contract?.identification?.ekycRequestParam,
            ...action.payload
          }
        }
      }
    }),

    deleteUploadFile: (state: OrderState, action: PayloadAction<FileLabelType>) =>
      deleteUploadFileCommon(state, action.payload),

    // 契約付加情報(第2階層用)
    setContractAdditionInfo: (state: OrderState, action: PayloadAction<Partial<ContractAdditionInfo>>) => ({
      ...state,
      contract: {
        ...state.contract,
        contractAdditionInfo: {
          ...state.contract?.contractAdditionInfo,
          ...action.payload
        }
      }
    }),

    // 受取店舗情報(第2階層用)
    setReceivingShopInformation: (state: OrderState, action: PayloadAction<Partial<ReceivingShopInformation>>) => ({
      ...state,
      orderAdditionInfo: {
        ...state.orderAdditionInfo,
        receivingShopInformation: {
          ...state.orderAdditionInfo?.receivingShopInformation,
          ...action.payload
        }
      }
    }),

    // 契約書類郵送フラグ
    setEnabledDeliveryDocument: (state: OrderState, action: PayloadAction<boolean>) => ({
      ...state,
      contract: {
        ...state.contract,
        contractAdditionInfo: {
          ...state.contract?.contractAdditionInfo,
          enabledDeliveryDocument: action.payload
        }
      }
    }),
    // 重要事項説明情報
    setImportantMatter: (state: OrderState, action: PayloadAction<Partial<ImportantMatterInfo>>) => ({
      ...state,
      contract: {
        ...state.contract,
        contractAdditionInfo: {
          ...state.contract?.contractAdditionInfo,
          importantMatterInfo: action.payload
        }
      }
    }),
    // 同意事項(重要事項説明)フラグ
    setImportantMatterAgree: (state: OrderState, action: PayloadAction<boolean>) => ({
      ...state,
      contract: {
        ...state.contract,
        contractAdditionInfo: {
          ...state.contract?.contractAdditionInfo,
          importantInfoAgree: action.payload
        }
      }
    }),
    // 同意事項(信用情報機関への基礎特定信用制度の通知)フラグ
    setCreditFacilityNoticeAgree: (state: OrderState, action: PayloadAction<boolean>) => ({
      ...state,
      contract: {
        ...state.contract,
        contractAdditionInfo: {
          ...state.contract?.contractAdditionInfo,
          creditFacilityNoticeAgree: action.payload
        }
      }
    }),
    // 同意事項(割賦販売法第4条の規定に基づく書面のEメール通知)フラグ
    setInstSalelawMailSendingAgree: (state: OrderState, action: PayloadAction<boolean>) => ({
      ...state,
      contract: {
        ...state.contract,
        contractAdditionInfo: {
          ...state.contract?.contractAdditionInfo,
          instSalelawMailSendingAgree: action.payload
        }
      }
    }),
    // 同意事項(強制開通の同意)フラグ
    setForcedOpeningAgree: (state: OrderState, action: PayloadAction<boolean>) => ({
      ...state,
      contract: {
        ...state.contract,
        contractAdditionInfo: {
          ...state.contract?.contractAdditionInfo,
          forcedOpeningAgree: action.payload
        }
      }
    }),

    // 見積画面から完了画面前までに使用する一連のステートをクリアする。
    resetOrderWork: (state: OrderState) => ({
      ...state,
      ...{
        ownedTerminal: undefined,
        estimate: {
          ...state.estimate,
          ...{
            contractTypeGroup: {
              ...state.estimate?.contractTypeGroup,
              ...{
                mnpReserveNumber: undefined,
                otherCompanyMobilePhoneTelNumber: undefined,
                mnpExpirationDate: undefined
              }
            },
            buyTerminal: {
              ...state.estimate?.buyTerminal,
              ...{
                useSim: undefined,
                simLockFree: undefined
              }
            },
            lineContract: undefined,
            lineContractDiscount: undefined,
            option: undefined,
            afterCareService: undefined,
            terminalBuyMethod: {
              ...state.estimate?.terminalBuyMethod,
              ...{
                changePrivilege: undefined,
                paymentMethods: undefined
              }
            },
            accessoryBuyMethod: undefined,
            discount: undefined
          }
        },
        contract: undefined,
        payment: undefined,
        orderAdditionInfo: {
          ...state.orderAdditionInfo,
          ...{
            receivingPlace: undefined,
            receivingShopInformation: undefined,
            deliveryDate: undefined,
            passwordForOrderContentConfirmation: undefined,
            rePasswordForOrderContentConfirmation: undefined,
            specifyDeliveryDate: undefined,
            specifyDeliveryTime: undefined,
            selectedSpecifyDeliveryDate: undefined,
            selectedSpecifyDeliveryTime: undefined,
            receivingPlaceAddress: undefined
          }
        }
      }
    }),

    addOptionalGoodsCartInfo: (state: OrderState, action: PayloadAction<CartInfo>) => ({
      ...state,
      estimate: {
        ...state.estimate,
        accessory: {
          ...state.estimate?.accessory,
          optionalGoodsCartInfoList: {
            [action.payload.productStockCode]: action.payload,
            ...state.estimate?.accessory?.optionalGoodsCartInfoList
          }
        }
      }
    }),
    deleteOptionalGoodsCartInfo: (state: OrderState, action: PayloadAction<string>) => {
      const workList = { ...state.estimate?.accessory?.optionalGoodsCartInfoList };
      delete workList[action.payload];
      return {
        ...state,
        estimate: {
          ...state.estimate,
          accessory: {
            ...state.estimate?.accessory,
            optionalGoodsCartInfoList: workList
          }
        }
      };
    },
    changeOptionalGoodsCartInfoPurchaseCount: (
      state: OrderState,
      action: PayloadAction<{ productStockCode: string; purchaseCount: number }>
    ) => {
      const cartInfo = state.estimate?.accessory?.optionalGoodsCartInfoList?.[action.payload.productStockCode];
      if (cartInfo) {
        return {
          ...state,
          estimate: {
            ...state.estimate,
            accessory: {
              ...state.estimate?.accessory,
              optionalGoodsCartInfoList: {
                ...state.estimate?.accessory?.optionalGoodsCartInfoList,
                [action.payload.productStockCode]: {
                  ...cartInfo,
                  purchaseCount: action.payload.purchaseCount
                }
              }
            }
          }
        };
      }
      return state;
    }
  }
});

// reducer共通処理
/**
 * 本人確認書類ファイル削除共通処理
 * @param state - ステート
 * @param deleteUploadFileNm - 本人確認書類ファイル名
 * @returns
 */
const deleteUploadFileCommon = (state: OrderState, deleteUploadFileNm: FileLabelType) => {
  const optionalIdentificationImageList = state.contract?.identification?.identificationImageList;
  const workList = { ...optionalIdentificationImageList };
  delete workList[deleteUploadFileNm];
  return {
    ...state,
    ...optionalIdentificationImageList
  };
};

export const { actions, reducer } = orderSlice;
