import { CaseReducer, combineReducers, createSlice } from '@reduxjs/toolkit';
import { order } from 'ols-order-webservice-typescript-client';
import { ServiceState } from '@/typings/ServiceState';
import {
  getPenaltyImmediatePoints,
  getCouponList,
  getAfterDayCampaigns,
  getDiscountServices,
  getAdditionalServices,
  getFinancialDiscounts
} from './thunk';

const initialState = {
  loaded: false,
  failed: false,
  response: null,
  error: null
};

/**
 * BFF-DCP-001: 適用可能違約金即時ポイント一覧取得API
 */
export const getPenaltyImmediatePointsSlice = createSlice<
  ServiceState<order.InstantPointsGetResponseV1>,
  Record<string, never>
>({
  name: 'discountCampaignService/getPenaltyImmediatePoints',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPenaltyImmediatePoints.pending, () => initialState)
      .addCase(getPenaltyImmediatePoints.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getPenaltyImmediatePoints.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-DCP-002: 割引施策一覧取得API
 */
export const getCouponListSlice = createSlice<
  ServiceState<order.CandidateCouponsGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'discountCampaignService/getCouponList',
  initialState,
  reducers: {
    resetGetCouponListState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCouponList.pending, () => initialState)
      .addCase(getCouponList.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getCouponList.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-DCP-003: 割引サービス情報取得API
 */
export const getDiscountServiceSlice = createSlice<
  ServiceState<order.DiscountServiceGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'discountCampaignService/getDiscountService',
  initialState,
  reducers: {
    resetGetDiscountServiceState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDiscountServices.pending, () => initialState)
      .addCase(getDiscountServices.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getDiscountServices.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-DCP-004: 後日キャンペーン適用情報取得API
 */
export const getAfterDayCampaignSlice = createSlice<
  ServiceState<order.AfterDayCampaignGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'discountCampaignService/getAfterDayCampaign',
  initialState,
  reducers: {
    resetGetAfterDayCampaignState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAfterDayCampaigns.pending, () => initialState)
      .addCase(getAfterDayCampaigns.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getAfterDayCampaigns.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-DCP-005: 付加サービス情報取得API
 */
export const getAdditionalServiceSlice = createSlice<
  ServiceState<order.AdditionalServiceGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'discountCampaignService/getAdditionalService',
  initialState,
  reducers: {
    resetGetAdditionalServiceState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdditionalServices.pending, () => initialState)
      .addCase(getAdditionalServices.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getAdditionalServices.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-DCP-007: 金融割情報取得API
 */
export const getFinancialDiscountSlice = createSlice<
  ServiceState<order.FinancialDiscountGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'discountCampaignService/getFinancialDiscount',
  initialState,
  reducers: {
    resetGetFinancialDiscountState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFinancialDiscounts.pending, () => initialState)
      .addCase(getFinancialDiscounts.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getFinancialDiscounts.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

export const reducers = combineReducers({
  getPenaltyImmediatePoints: getPenaltyImmediatePointsSlice.reducer,
  getCouponList: getCouponListSlice.reducer,
  getDiscountService: getDiscountServiceSlice.reducer,
  getAfterDayCampaign: getAfterDayCampaignSlice.reducer,
  getAdditionalService: getAdditionalServiceSlice.reducer,
  getFinanciallyDiscountService: getFinancialDiscountSlice.reducer
});

export const { actions } = getCouponListSlice;
