import type { RootState } from '@/store';
/**
 * セクション状態取得
 * @param state - ステート
 * @returns セクション状態（SectionStatuses）
 * @example
 * import \{ useAppSelector \} from '\@/store/hooks';
 * import \{ sectionStatusSelectors \} from '\@/store/sectionStatus';
 * const \{ sectionContractType, sectionContractDetail \} = useAppSelector(sectionStatusSelectors.getSectionStatus);
 */
export const getSectionStatus = (state: RootState): RootState['flow']['sectionStatus'] => state.flow.sectionStatus;
/**
 * セクション①状態取得
 * @param state - ステート
 * @returns セクション状態（SectionStatuses）
 */
export const getSectionStatusContractType = (
  state: RootState
): RootState['flow']['sectionStatus']['sectionContractType'] => state.flow.sectionStatus.sectionContractType;
/**
 * セクション②状態取得
 * @param state - ステート
 * @returns セクション状態（SectionStatuses）
 */
export const getSectionStatusContractDetail = (
  state: RootState
): RootState['flow']['sectionStatus']['sectionContractDetail'] => state.flow.sectionStatus.sectionContractDetail;
/**
 * セクション③状態取得
 * @param state - ステート
 * @returns セクション状態（SectionStatuses）
 */
export const getSectionStatusPointCouponUse = (
  state: RootState
): RootState['flow']['sectionStatus']['sectionPointCouponUse'] => state.flow.sectionStatus.sectionPointCouponUse;
/**
 * セクション④状態取得
 * @param state - ステート
 * @returns セクション状態（SectionStatuses）
 */
export const getSectionStatusContractorInformation = (
  state: RootState
): RootState['flow']['sectionStatus']['sectionContractorInformation'] =>
  state.flow.sectionStatus.sectionContractorInformation;
/**
 * セクション⑤状態取得
 * @param state - ステート
 * @returns セクション状態（SectionStatuses）
 */
export const getSectionStatusIdentification = (
  state: RootState
): RootState['flow']['sectionStatus']['sectionIdentification'] => state.flow.sectionStatus.sectionIdentification;
/**
 * セクション⑥状態取得
 * @param state - ステート
 * @returns セクション状態（SectionStatuses）
 */
export const getSectionStatusReceivingMethod = (
  state: RootState
): RootState['flow']['sectionStatus']['sectionReceivingMethod'] => state.flow.sectionStatus.sectionReceivingMethod;
/**
 * セクション⑦状態取得
 * @param state - ステート
 * @returns セクション状態（SectionStatuses）
 */
export const getSectionStatusPasswordForOrderContentConfirmation = (
  state: RootState
): RootState['flow']['sectionStatus']['sectionPasswordForOrderContentConfirmation'] =>
  state.flow.sectionStatus.sectionPasswordForOrderContentConfirmation;

/**
 * クイックセクション状態取得
 * @param state - ステート
 * @returns セクション状態（SectionStatuses）
 */
export const getSectionStatusQuickChange = (
  state: RootState
): RootState['flow']['sectionStatus']['sectionQuickChange'] => state.flow.sectionStatus.sectionQuickChange;
