import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import { SystemApiService } from '@/services/systemApiService';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { ContractType } from '@/typings/ContractType';
import { DeviceCategoryValue } from '@/typings/DeviceCategory';
import { OsType } from '@/typings/OsType';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';

export type LoadSucceeded = { id: string; content: string };
export type LoadFailed = { id: string; error: string };

/**
 * BFF-SYS-001: includeコンテンツ取得API
 */
export const getIncludes = createAsyncThunk<
  // Api正常時レスポンスの型
  order.IncludeContentResponseV1[],
  // Apiリクエストパラメータの型
  {
    includeIDs: Array<string>;
    productStockCode?: string;
    contractType?: ContractType;
    deviceCategory?: DeviceCategoryValue;
    osType?: OsType;
    contractorBirthday?: string;
    userBirthday?: string;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'systemService/getIncludes',
  async (
    { includeIDs, productStockCode, contractType, deviceCategory, osType, contractorBirthday, userBirthday },
    { rejectWithValue }
  ) => {
    const config = apiConfigProvider();
    try {
      return await SystemApiService.getInstance(config).getIncludes(
        includeIDs,
        productStockCode,
        contractType,
        deviceCategory,
        osType,
        contractorBirthday,
        userBirthday
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-SYS-001'));
    }
  }
);
