/**
 * 性別を表すコード値
 */
export type GenderType = 'Male' | 'Female';

/**
 * 性別Enum
 */
export const Gender = {
  MALE: 'Male', // 男性
  FEMALE: 'Female' // 女性
} as const;

/**
 * 性別ラベル
 */
export const GenderLabels = {
  Male: '男性',
  Female: '女性'
} as const;

/**
 * 性別を表すコード値
 */
export type SexType = '1' | '2';

export const SexNo = {
  MALE: '1', // 男性
  FEMALE: '2' // 女性
} as const;
/**
 * 性別ラベル
 */
export const SexLabels = {
  '1': '男性',
  '2': '女性'
} as const;
