import type { RootState } from '@/store';

/**
 * BFF-EST-001: 注文見積もり情報取得API
 * @param state - ステート
 * @returns
 */
export const getEstimatingOrderResult = (
  state: RootState
): RootState['entity']['estimateApiService']['getEstimatingOrder'] =>
  state.entity.estimateApiService.getEstimatingOrder;

/**
 * BFF-EST-002: 注文見積もり情報登録API
 * @param state - ステート
 * @returns
 */
export const createEstimatingOrderResult = (
  state: RootState
): RootState['entity']['estimateApiService']['createEstimatingOrder'] =>
  state.entity.estimateApiService.createEstimatingOrder;

/**
 * BFF-EST-003: 本申込情報取得API
 * @param state - ステート
 * @returns
 */
export const getPendingReservationsInfoResult = (
  state: RootState
): RootState['entity']['estimateApiService']['getPendingReservationsInfo'] =>
  state.entity.estimateApiService.getPendingReservationsInfo;

/**
 * BFF-EST-004: 見積保存メール情報取得API
 * @param state - ステート
 * @returns
 */
export const getEstimateSaveMailByEstimateIdResult = (
  state: RootState
): RootState['entity']['estimateApiService']['getEstimateSaveMailByEstimateId'] =>
  state.entity.estimateApiService.getEstimateSaveMailByEstimateId;

/**
 * BFF-EST-005: 見積保存メール情報登録API
 * @param state - ステート
 * @returns
 */
export const createEstimateSaveMailByEstimateIdResult = (
  state: RootState
): RootState['entity']['estimateApiService']['createEstimateSaveMailByEstimateId'] =>
  state.entity.estimateApiService.createEstimateSaveMailByEstimateId;

/**
 * BFF-EST-006: 料金費用内訳計算API
 * @param state - ステート
 * @returns
 */
export const postEstimatedAmountsResult = (
  state: RootState
): RootState['entity']['estimateApiService']['postEstimatedAmounts'] =>
  state.entity.estimateApiService.postEstimatedAmounts;

/**
 * BFF-EST-007: 割引施策セッション初期化API
 * @param state - ステート
 * @returns
 */
export const getEstimateSessionClearResult = (
  state: RootState
): RootState['entity']['estimateApiService']['getEstimateSessionClear'] =>
  state.entity.estimateApiService.getEstimateSessionClear;

/**
 * BFF-EST-010: 暫定料金費用内訳計算API
 * @param state - ステート
 * @returns
 */
export const postEstimatedTemporaryAmountResult = (
  state: RootState
): RootState['entity']['estimateApiService']['postEstimatedTemporaryAmounts'] =>
  state.entity.estimateApiService.postEstimatedTemporaryAmounts;
