import { order } from 'ols-order-webservice-typescript-client';
import { AreaCode } from '@/typings/AreaCode';
import { ContractBranchCode } from '@/typings/ContractBranchCode';
import { MobileDeviceJudgment } from '@/typings/MobileDeviceJudgment';
import { NetworkType } from '@/typings/NetworkType';
import { consoleLog } from '@/utils/functions';

const { ProductApi } = order;

export class ProductApiService {
  private static instance: ProductApiService;

  private api: order.ProductApi;

  private constructor(config: order.Configuration) {
    this.api = new ProductApi(config);
  }

  public static getInstance(config: order.Configuration): ProductApiService {
    if (!this.instance) {
      this.instance = new ProductApiService(config);
    }

    return this.instance;
  }

  /**
   * BFF-PRD-001: 販売商品情報取得API
   * @param productStockCode - 取得したい商品の商品コードを設定します。
   * @returns
   */
  getSalesProduct(productStockCode: string, deviceType: '1' | '2'): Promise<order.SalesProductGetResponseV1> {
    consoleLog('BFF-PRD-001: 販売商品情報取得API call', productStockCode, deviceType);
    return this.api.getSalesProductV1(productStockCode, deviceType).then((res) => res.data);
  }

  /**
   * BFF-PRD-002: 保証・サポートサービス取得API
   * @param productStockCode - 注文商品の商品コードを指定します。
   * @param tCategoryOptionCodes - ユーザーの契約関連情報に含まれるT系オプションコード一覧を指定します。
   * @param auContractId - au契約IDを指定します。
   * @param acrossAftercareCategory - ACROSS1の故障紛失サポート区分を指定します。
   */
  getCandidateAfterSaleServices(
    productStockCode: string,
    tCategoryOptionCodes: Array<string>,
    contractType: '1' | '2' | '3' | '4' | '5',
    auContractId: string,
    acrossApplecareCode?: string,
    acrossAftercareCode?: string,
    acrossAftercareCategory?: string,
    acrossUseSupportCode?: string,
    leonApplecareCode?: string,
    leonDeviceCompensationCode?: string,
    uqUserStatus?: '0' | '1',
    simOnlyFlag?: boolean
  ): Promise<order.CandidateAfterSaleServicesGetResponseV1> {
    consoleLog(
      'BFF-PRD-002: 保証・サポートサービス取得API call',
      productStockCode,
      tCategoryOptionCodes,
      contractType,
      auContractId,
      acrossApplecareCode,
      acrossAftercareCode,
      acrossAftercareCategory,
      acrossUseSupportCode,
      leonApplecareCode,
      leonDeviceCompensationCode,
      uqUserStatus,
      simOnlyFlag
    );
    return this.api
      .getCandidateAfterSaleServicesV1(
        productStockCode,
        tCategoryOptionCodes,
        contractType,
        auContractId,
        acrossApplecareCode,
        acrossAftercareCode,
        acrossAftercareCategory,
        acrossUseSupportCode,
        leonApplecareCode,
        leonDeviceCompensationCode,
        uqUserStatus,
        simOnlyFlag
      )
      .then((res) => res.data);
  }

  /**
   * BFF-PRD-003: 端末割賦代金マスタAPI
   * @param productStockCode - 商品コード
   * @param contractType - 契約種別
   * @param areaCodeCode - 契約都道府県
   * @param contractBranchCode - 契約支社
   * @param mvnoCode - MVNO事業者識別コード
   * @param kddioct - KDDI/OCT区分
   * @param agencyCode - 代理店コード
   * @returns
   */
  getInstallmentPriceByProductStockCode(
    productStockCode: string,
    contractType: '1' | '2' | '3' | '4' | '5',
    areaCodeCode?: AreaCode,
    contractBranchCode?: ContractBranchCode,
    mvnoCode?: string,
    agencyCode?: string,
    kddioct?: string
  ): Promise<order.DeviceInstallmentPriceByProductStockCodeGetResponseV1> {
    consoleLog(
      'BFF-PRD-003: 端末割賦代金マスタAPI call',
      productStockCode,
      contractType,
      areaCodeCode,
      contractBranchCode,
      mvnoCode,
      agencyCode,
      kddioct
    );
    return this.api
      .getInstallmentPriceByProductStockCodeV1(
        productStockCode,
        contractType,
        areaCodeCode,
        contractBranchCode,
        mvnoCode,
        agencyCode,
        kddioct
      )
      .then((res) => res.data);
  }

  /**
   * BFF-PRD-004: アクセサリー一覧取得API
   * @param productStockCode - 注文商品の商品コードを指定します。
   * @param contractType - 契約種別を設定します。
   * @param salesMobileDeviceCode - 販売移動機商品コード
   * @param orderNo - 注文番号
   * @param mobileDeviceJudgment - 移動機種判定 アカウント取得API（BFF-USR-001）から取得した値を指定してください。
   * @param auPhoneUsePeriod - au電話機利用期間 アカウント取得API（BFF-USR-001）から取得した値を指定してください。
   * @param mvnoCode - MVNO事業者識別コード
   * @returns
   */
  getOptionalProducts(
    productStockCode: string,
    contractType: '1' | '2' | '3' | '4' | '5',
    salesMobileDeviceCode?: string,
    orderNo?: string,
    mobileDeviceJudgment?: MobileDeviceJudgment,
    auPhoneUsePeriod?: string,
    mvnoCode?: string
  ): Promise<order.AccessoriesGetResponseV1> {
    consoleLog(
      'BFF-PRD-004: アクセサリー一覧取得API call',
      productStockCode,
      contractType,
      salesMobileDeviceCode,
      orderNo,
      mobileDeviceJudgment,
      auPhoneUsePeriod,
      mvnoCode
    );
    return this.api
      .getOptionalProductsV1(
        productStockCode,
        contractType,
        salesMobileDeviceCode,
        orderNo,
        mobileDeviceJudgment,
        auPhoneUsePeriod,
        mvnoCode
      )
      .then((res) => res.data);
  }

  /**
   * BFF-PRD-005: アクセサリー詳細情報取得API
   * @param productStockCode - 取得したい商品の商品コードを設定します。
   * @returns
   */
  getOptionalProductDetail(productStockCode: string): Promise<order.AccessoryDetailGetResponseV1> {
    consoleLog('BBFF-PRD-005: アクセサリー詳細情報取得API call', productStockCode);
    return this.api.getOptionalProductDetailV1(productStockCode).then((res) => res.data);
  }

  /**
   * BFF-PRD-006: 機種変更区分取得API
   * @param networkType - 販売商品情報に含まれるネットワーク区分を指定します。
   * @param romIcCardDeviceCategory - 注文商品のROM機/ICカードを指定します。
   * @param eSimFlag - eSIMフラグを指定します。
   * @param auPhoneUsePeriod - au電話機利用期間
   * @param densyu - 電種を指定します。
   * @param mobileDeviceJudgment - 移動機種判定
   * @param productAttribute - 商品属性を指定します。
   * @param microSimType - microSIM区分を指定します。
   * @param singleDeviceType -シングル端末種類
   * @param winIsSeriesDeviceCategory - WIN/ISシリーズ/iPhone
   * @returns
   */
  getDecisionDeviceChangeIncrease(
    networkType: NetworkType,
    romIcCardDeviceCategory: '5' | '6' | '7' | '8' | '9' | '10' | '12' | '13' | '14' | '15',
    eSimFlag: boolean,
    auPhoneUsePeriod: string,
    densyu?: string,
    mobileDeviceJudgment?: MobileDeviceJudgment,
    productAttribute?: string,
    microSim?: '0' | '1',
    singleDeviceType?: '1' | '2' | '3' | '4',
    winIsSeriesDeviceCategory?: '1' | '2' | '3' | '4' | '5'
  ): Promise<order.DeviceChangeTypeGetResponseV1> {
    consoleLog(
      'BFF-PRD-006: 機種変更区分取得API call',
      networkType,
      romIcCardDeviceCategory,
      eSimFlag,
      auPhoneUsePeriod,
      densyu,
      mobileDeviceJudgment,
      productAttribute,
      microSim,
      singleDeviceType,
      winIsSeriesDeviceCategory
    );
    return this.api
      .getDecisionDeviceChangeIncreaseV1(
        networkType,
        romIcCardDeviceCategory,
        eSimFlag,
        auPhoneUsePeriod,
        densyu,
        mobileDeviceJudgment,
        productAttribute,
        microSim,
        singleDeviceType,
        winIsSeriesDeviceCategory
      )
      .then((res) => res.data);
  }

  /**
   * BFF-PRD-007: AppleWatch用保証・サポートサービス取得API
   * @param productStockCode - 注文商品の商品コードを指定します。
   * @param appleWatchProductStockCode - AppleWatch用AppleCareの情報を取得したいAppleWatchの商品コードを指定します。
   * @param contractType - 契約種別を設定します。
   * @returns
   */
  getCandidateAfterSaleServicesForAppleWatch(
    productStockCode: string,
    appleWatchProductStockCode: string,
    contractType: '1' | '2' | '3' | '4' | '5' | '8' | '9'
  ): Promise<order.CandidateAfterSaleServicesForAppleWatchGetResponseV1> {
    consoleLog(
      'BFF-PRD-007: AppleWatch用保証・サポートサービス取得API call',
      productStockCode,
      appleWatchProductStockCode,
      contractType
    );
    return this.api
      .getCandidateAfterSaleServicesForAppleWatchV1(productStockCode, contractType, appleWatchProductStockCode)
      .then((res) => res.data);
  }

  /**
   * BFF-PRD-008: お届け可能日取得API
   * @param productStockCode - 注文商品の商品コードを指定します。
   * @param sameDayDeliveryAvailable - 即日配送可否
   * @param postalCode - 郵便番号
   * @param contractType - 契約種別を設定します。
   * @param optionGoodsList - OP品の商品コード
   * @returns
   */
  getDeliveryAvailableDate(
    productStockCode: string,
    sameDayDeliveryAvailable: '1' | '0',
    postalCode: string,
    contractType?: '1' | '2' | '3' | '4' | '5' | '8' | '9',
    optionGoodsList?: Array<string>
  ): Promise<order.DeliveryAvailableDateListGetResponseV1> {
    consoleLog(
      'BFF-PRD-008: お届け可能日取得API call',
      productStockCode,
      sameDayDeliveryAvailable,
      postalCode,
      contractType,
      optionGoodsList
    );
    return this.api
      .getDeliveryAvailableDateV1(productStockCode, sameDayDeliveryAvailable, postalCode, contractType, optionGoodsList)
      .then((res) => res.data);
  }
}
