import { AppDispatch } from '@/store';
import { PointCouponTitleCode } from '@/typings/PointCouponTitleCode';
import { ScreenIdValue } from '@/typings/ScreenId';
import {
  resetInputBirthdayConfirmFlag,
  resetLiquidClaimReview,
  resetPhotoButtonFlag,
  resetViewCommon,
  setTrueEkycFlag,
  setViewCommon,
  resetExternalLastScreenId
} from './actions';
import { ViewCommonState } from './viewCommonSlice';

/**
 * 画面共通情報更新オペレータ
 * @param state - ステート
 * @returns
 */
export const set = (state: Partial<ViewCommonState>) => (dispatch: AppDispatch): ReturnType<typeof setViewCommon> =>
  dispatch(setViewCommon(state));

/**
 * 画面共通情報初期化オペレータ
 * @returns
 */
export const reset = () => (dispatch: AppDispatch): ReturnType<typeof resetViewCommon> => dispatch(resetViewCommon());

/**
 * LIQUID審査申請情報初期化オペレータ
 * @returns
 */
export const resetLiquid = () => (dispatch: AppDispatch): ReturnType<typeof resetLiquidClaimReview> =>
  dispatch(resetLiquidClaimReview());

/**
 * 生年月日モーダル正常完了判定フラグ初期化オペレータ
 * @returns
 */
export const resetInputBirthday = () => (dispatch: AppDispatch): ReturnType<typeof resetInputBirthdayConfirmFlag> =>
  dispatch(resetInputBirthdayConfirmFlag());

/**
 * 撮影に進むor撮影をやり直す押下フラグ
 * @returns
 */
export const resetEkyc = () => (dispatch: AppDispatch): ReturnType<typeof resetPhotoButtonFlag> =>
  dispatch(resetPhotoButtonFlag());

/**
 * 遷移元画面ID設定オペレータ
 */
export const setLastScreenId = (lastScreenId: ScreenIdValue | '') => (
  dispatch: AppDispatch
): ReturnType<typeof setViewCommon> => dispatch(setViewCommon({ lastScreenId }));

/**
 * 接続元端末種別
 */
export const setAccessDeviceType = (accessDeviceType: string) => (
  dispatch: AppDispatch
): ReturnType<typeof setViewCommon> => dispatch(setViewCommon({ accessDeviceType }));

/**
 * LIQUID審査申請API初回正常フラグ
 */
export const setLiquidClaimReviewInfoFirstNormalFlg = (liquidClaimReviewInfoFirstNormalFlg: boolean) => (
  dispatch: AppDispatch
): ReturnType<typeof setViewCommon> => dispatch(setViewCommon({ liquidClaimReviewInfoFirstNormalFlg }));

/**
 * LIQUID審査申請API正常フラグ(ブラウザバック判定用)
 */
export const setLiquidClaimReviewTransferNormalFlg = (liquidClaimReviewTransferNormalFlg: boolean) => (
  dispatch: AppDispatch
): ReturnType<typeof setViewCommon> => dispatch(setViewCommon({ liquidClaimReviewTransferNormalFlg }));

/**
 * eKYCが一度でも選択されCVボタンが押下されたことを判定する用のフラグ
 */
export const setEkycFlag = () => (dispatch: AppDispatch): ReturnType<typeof setTrueEkycFlag> =>
  dispatch(setTrueEkycFlag());

/**
 * ポイントクーポンの画面表示用文言
 */
export const setPointCouponTitleCode = (pointCouponTitleCode: PointCouponTitleCode) => (
  dispatch: AppDispatch
): ReturnType<typeof setViewCommon> => dispatch(setViewCommon({ pointCouponTitleCode }));

/**
 * 遷移元画面ID（外部経由）設定オペレータ
 */
export const setExternalLastScreenId = (externalLastScreenId: ScreenIdValue | undefined) => (
  dispatch: AppDispatch
): ReturnType<typeof setViewCommon> => dispatch(setViewCommon({ externalLastScreenId }));

/**
 * 遷移元画面ID（外部経由）初期化オペレータ
 * @returns
 */
export const resetExternalLastScreen = () => (dispatch: AppDispatch): ReturnType<typeof resetExternalLastScreenId> =>
  dispatch(resetExternalLastScreenId());

/**
 * スクロール先の要素のID
 */
export const setScrollTargetElementId = (scrollTargetElementId: string | undefined) => (
  dispatch: AppDispatch
): ReturnType<typeof setViewCommon> => dispatch(setViewCommon({ scrollTargetElementId }));
