import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Footer, TextLink, Icon } from 'ols-order-components';
import { REACT_APP_TERMS_REQUIREMENTS_URL } from '@/config/env';
import { ScreenUrlPaths } from '@/typings/ScreenUrlPath';
import { Include } from '../Include';

const footerIncludeId: (pathname: string) => string | undefined = (pathname) => {
  if (pathname.indexOf(ScreenUrlPaths.ContractType) >= 0) {
    return 'UAD0100_include010';
  }

  if (pathname.indexOf(ScreenUrlPaths.PhoneNumberSelection) >= 0) {
    return 'UAD0200_include002';
  }

  if (pathname.indexOf(ScreenUrlPaths.Estimate) >= 0) {
    return 'UAD0500_include119';
  }

  if (pathname.indexOf(ScreenUrlPaths.Confirming) >= 0) {
    return 'UAD0600_include007';
  }

  if (pathname.indexOf(ScreenUrlPaths.Completed) >= 0) {
    return 'UAD0800_include004';
  }

  return undefined;
};

/**
 * Footer Wrapperコンポーネント
 * @returns
 */
const FooterWrapComponent: React.FC = () => {
  const { pathname } = useLocation();
  const includeId = useMemo(() => footerIncludeId(pathname), [pathname]);

  return (
    <Footer isLogoOnly={!includeId}>
      <>
        {includeId && <Include id={includeId} />}
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <TextLink
            onClick={() => {
              window.open(REACT_APP_TERMS_REQUIREMENTS_URL || '', '_blank');
            }}
          >
            動作環境・Cookie情報の利用、広告配信等について
            <Icon variant="blank" />
          </TextLink>
        </div>
      </>
    </Footer>
  );
};

export const FooterWrap = React.memo(FooterWrapComponent);
