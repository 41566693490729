import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import { EstimateApiService } from '@/services/estimateApiService';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';

/**
 * BFF-EST-001: 注文見積もり情報取得API
 */
export const getEstimatingOrder = createAsyncThunk<
  // Api正常時レスポンスの型
  void,
  // Apiリクエストパラメータの型
  void,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('estimateApiService/getEstimatingOrder', async (_, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await EstimateApiService.getInstance(config).getEstimatingOrder();
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-EST-001'));
  }
});

/**
 * BFF-EST-002: 注文見積もり情報登録API
 */
export const createEstimatingOrder = createAsyncThunk<
  // Api正常時レスポンスの型
  order.EstimatingOrderPostResponseV1,
  // Apiリクエストパラメータの型
  {
    estimatingOrderPostRequestV1?: order.EstimatingOrderPostRequestV1;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('estimateApiService/createEstimatingOrder', async ({ estimatingOrderPostRequestV1 }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await EstimateApiService.getInstance(config).createEstimatingOrder(estimatingOrderPostRequestV1);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-EST-002'));
  }
});

/**
 * BFF-EST-003: 本申込情報取得API
 */
export const getPendingReservationsInfo = createAsyncThunk<
  // Api正常時レスポンスの型
  order.PendingReservationsInfoGetResponseV1,
  // Apiリクエストパラメータの型
  {
    orderNo: string;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('estimateApiService/getPendingReservationsInfo', async ({ orderNo }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await EstimateApiService.getInstance(config).getPendingReservationsInfo(orderNo);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-EST-003'));
  }
});

/**
 * BFF-EST-004: 見積保存メール情報取得API
 */
export const getEstimateSaveMailByEstimateId = createAsyncThunk<
  // Api正常時レスポンスの型
  order.EstimateSaveMailByEstimateIdGetResponseV1,
  // Apiリクエストパラメータの型
  {
    hashId: string;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('estimateApiService/getEstimateSaveMailByEstimateId', async ({ hashId }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await EstimateApiService.getInstance(config).getEstimateSaveMailByEstimateId(hashId);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-EST-004'));
  }
});

/**
 * BFF-EST-005: 見積保存メール情報登録API
 */
export const createEstimateSaveMailByEstimateId = createAsyncThunk<
  // Api正常時レスポンスの型
  void,
  // Apiリクエストパラメータの型
  {
    estimateSaveMailByEstimateId?: order.EstimateSaveMailByEstimateId;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'estimateApiService/createEstimateSaveMailByEstimateId',
  async ({ estimateSaveMailByEstimateId }, { rejectWithValue }) => {
    const config = apiConfigProvider();
    try {
      return await EstimateApiService.getInstance(config).createEstimateSaveMailByEstimateId(
        estimateSaveMailByEstimateId
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-EST-005'));
    }
  }
);

/**
 * BFF-EST-006: 料金費用内訳計算API
 */
export const postEstimatedAmounts = createAsyncThunk<
  // Api正常時レスポンスの型
  order.EstimateAmountsPostResponseV1,
  // Apiリクエストパラメータの型
  {
    estimateAmountsPostRequestV1?: order.EstimateAmountsPostRequestV1;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('estimateApiService/postEstimatedAmounts', async ({ estimateAmountsPostRequestV1 }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await EstimateApiService.getInstance(config).postEstimatedAmounts(estimateAmountsPostRequestV1);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-EST-006'));
  }
});

/**
 * BFF-EST-007: 割引施策セッション初期化API
 */
export const getEstimateSessionClear = createAsyncThunk<
  // Api正常時レスポンスの型
  void,
  // Apiリクエストパラメータの型
  void,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('estimateApiService/getEstimateSessionClear', async (_, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await EstimateApiService.getInstance(config).getEstimateSessionClear();
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-EST-007'));
  }
});

/**
 * BFF-EST-010: 暫定料金費用内訳計算API
 */
export const postEstimatedTemporaryAmounts = createAsyncThunk<
  // Api正常時レスポンスの型
  order.EstimateTemporaryAmountsPostResponseV1,
  // Apiリクエストパラメータの型
  {
    estimateAmountsPostRequestV1?: order.EstimateTemporaryAmountsPostRequestV1;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('estimateApiService/postEstimatedTemporaryAmounts', async ({ estimateAmountsPostRequestV1 }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await EstimateApiService.getInstance(config).postEstimatedTemporaryAmounts(estimateAmountsPostRequestV1);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-EST-010'));
  }
});
