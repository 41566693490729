import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { order } from 'ols-order-webservice-typescript-client';
import { AxiosError } from 'axios';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { DataPlanInformation } from '@/services/dataPlanInformationApi';
import { AreaCode } from '@/typings/AreaCode';
import { RootState } from '@/store';
import { ServiceState } from '@/typings/ServiceState';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';
import { ContractType } from '@/typings/ContractType';
import { MobileDeviceJudgment } from '@/typings/MobileDeviceJudgment';
import { ContractBranchCode } from '@/typings/ContractBranchCode';

const initialState: ServiceState<order.PlansResponseV1> = {
  loaded: false,
  failed: false,
  response: null,
  error: null
};

/**
 * BFF-CHP-006: 選択可能データプラン・通話オプション情報取得API
 */
export const getCandidatePlanV1 = createAsyncThunk<
  // Api正常時レスポンスの型
  order.PlansResponseV1,
  // Apiリクエストパラメータの型
  {
    contractType: ContractType;
    networkType: order.NetworkTypeBffEnum;
    productType?: order.ProductTypeBffEnum;
    winIsSeries?: order.WinIsSeriesDeviceCategoryBffEnum;
    singleDeviceType?: order.SingleDeviceTypeBffEnum;
    mobileDeviceCode: string;
    annualContractCode: order.AnnualContractCodeBffEnum;
    eSimFlag?: boolean | undefined;
    densyu?: string | undefined;
    mobileDeviceJudgment?: MobileDeviceJudgment;
    productAttribute?: string | undefined;
    microSimType?: order.MicroSimBffEnum | undefined;
    auPhoneUsePeriod?: string | undefined;
    auTelephoneNumber?: string | undefined;
    contractorBirthday?: string | undefined;
    userBirthday?: string | undefined;
    mnpReservedNumber?: string | undefined;
    mnpMnoNo?: string | undefined;
    kddioct?: string | undefined;
    tOptionCodes?: string[] | undefined;
    romIcCardDeviceCategory?: '8' | '5' | '6' | '7' | '9' | '10' | '12' | '13' | '14' | '15' | undefined;
    contractBranchCode?: ContractBranchCode;
    areaCodeCode?: AreaCode;
    mvnoCode?: string;
    agencyCode?: string | undefined;
    tabletSharePlanAvailable?: boolean;
    singleSimFlag?: '1';
    smartPhoneStartPlanAvailable?: boolean;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'dataPlanInformation/getCandidatePlanV1',
  async (
    {
      contractType,
      networkType,
      mobileDeviceCode,
      annualContractCode,
      productType,
      winIsSeries,
      singleDeviceType,
      eSimFlag,
      densyu,
      mobileDeviceJudgment,
      productAttribute,
      microSimType,
      auPhoneUsePeriod,
      auTelephoneNumber,
      contractorBirthday,
      userBirthday,
      mnpReservedNumber,
      mnpMnoNo,
      kddioct,
      tOptionCodes,
      romIcCardDeviceCategory,
      contractBranchCode,
      areaCodeCode,
      mvnoCode,
      agencyCode,
      tabletSharePlanAvailable,
      singleSimFlag,
      smartPhoneStartPlanAvailable
    },
    { rejectWithValue }
  ) => {
    const config = apiConfigProvider();
    try {
      return await DataPlanInformation.getInstance(config).getCandidatePlanV1(
        contractType,
        networkType,
        mobileDeviceCode,
        annualContractCode,
        productType,
        winIsSeries,
        singleDeviceType,
        eSimFlag,
        densyu,
        mobileDeviceJudgment,
        productAttribute,
        microSimType,
        auPhoneUsePeriod,
        auTelephoneNumber,
        contractorBirthday,
        userBirthday,
        mnpReservedNumber,
        mnpMnoNo,
        kddioct,
        tOptionCodes,
        romIcCardDeviceCategory,
        contractBranchCode,
        areaCodeCode,
        mvnoCode,
        agencyCode,
        tabletSharePlanAvailable,
        singleSimFlag,
        smartPhoneStartPlanAvailable
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err as AxiosError<order.ApiErrors>;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-CHP-006'));
    }
  }
);

const slice = createSlice({
  name: 'dataPlanInformation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCandidatePlanV1.pending, () => initialState)
      .addCase(getCandidatePlanV1.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getCandidatePlanV1.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

export const getCandidatePlanV1Result = (state: RootState): RootState['entity']['dataPlanInformationApi'] =>
  state.entity.dataPlanInformationApi;

export const { actions, reducer } = slice;
