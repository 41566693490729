import {
  AnyAction,
  combineReducers,
  configureStore,
  createAction,
  getDefaultMiddleware,
  Reducer
} from '@reduxjs/toolkit';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import { REACT_APP_ENV } from '@/config/env';
import { include } from './include';
import { network } from './network';
import { productService } from './api/product';
import { userService } from './api/user';
import { dataPlanInformationApi } from './api/candidatePlanInformation';
import { yearsContractInformationApi } from './api/yearsContractInformation';
import { internetConnectionServicesApi } from './api/internetConnectionServices';
import { auPayCardPaymentDiscountsApi } from './api/auPayCardPaymentDiscounts';
import { systemService } from './api/system';
import { addressService } from './api/address';
import { accessory } from './flow/accessory';
import { sectionStatus } from './flow/sectionStatus';
import { sectionPointCouponUse } from './flow/sectionPointCouponUse';
import { requestParameter } from './flow/requestParameter';
import { error } from './flow/error';
import { system } from './flow/system';
import { viewCommon } from './flow/viewCommon';
import { order } from './order';
import { tokenCorpInfoApi } from './api/TokenCorpInfo';
import { orderPaymentService } from './api/orderPayment';
import { estimateService } from './api/estimate';
import { identificationService } from './api/identification';
import { discountCampaignService } from './api/discountCampaign';
import { agencyService } from './api/agency';
import { shopSelectionScreenStatus } from './flow/shopSelectionScreenStatus';
import { couponService } from './api/coupon';
import { contractDetailScreenStatus } from './flow/contractDetailScreenStatus';
import { onetimePasswordStatus } from './flow/onetimePasswordStatus';

/**
 * entity reducer定義
 */
const entity = combineReducers({
  /**
   * 以下にBFF-APIのアクセスとデータ保持を行うreducerを定義する
   */
  productService: productService.reducers,
  estimateApiService: estimateService.reducers,
  orderPaymentService: orderPaymentService.reducers,
  identificationService: identificationService.reducers,
  discountCampaignService: discountCampaignService.reducers,
  couponService: couponService.reducers,
  systemService: systemService.reducers,
  addressService: addressService.reducers,
  userService: userService.reducers,
  agencyService: agencyService.reducers,

  dataPlanInformationApi: dataPlanInformationApi.reducer,
  yearsContractInformationApi: yearsContractInformationApi.reducer,
  internetConnectionServicesApi: internetConnectionServicesApi.reducer,
  auPayCardPaymentDiscountsApi: auPayCardPaymentDiscountsApi.reducer,
  tokenCorpInfoApi: tokenCorpInfoApi.reducer
});

/**
 * flow reducer定義
 */
const flow = combineReducers({
  /**
   * 以下に画面制御系・インクルード処理・クエリパラメータ等の保持を行うreducerを定義する
   */
  sectionStatus: sectionStatus.reducer,
  requestParameter: requestParameter.reducer,
  accessory: accessory.reducer,
  sectionPointCouponUse: sectionPointCouponUse.reducer,
  system: system.reducer,
  viewCommon: viewCommon.reducer,
  shopSelectionScreenStatus: shopSelectionScreenStatus.reducer,
  contractDetailScreenStatus: contractDetailScreenStatus.reducer,
  onetimePasswordStatus: onetimePasswordStatus.reducer
});

/**
 * root reducer定義
 */
const root = combineReducers({
  order: order.reducer,
  error: error.reducer,
  entity,
  flow,
  include: include.reducer,
  network: network.reducer
});

/**
 * stateリセット用Reducer
 * @param reducer - reducer
 */
const resettableReducer = (reducer: Reducer) => (state: Parameters<typeof root>[0], action: AnyAction) => {
  if (action.type === RESET_ACTION_TYPE) {
    const tmp = {
      network: state?.network
    } as Parameters<typeof root>[0];
    return reducer(tmp, action);
  }
  if (action.type === RESET_EXCEPT_REQUEST_PARAM_ACTION_TYPE) {
    const tmp = {
      flow: {
        requestParameter: {
          ...state?.flow.requestParameter
        },
        viewCommon: {
          lastScreenId: state?.flow.viewCommon.lastScreenId
        }
      },
      network: state?.network
    } as Parameters<typeof root>[0];
    return reducer(tmp, action);
  }
  if (action.type === RESET_ORDER_ERROR_ACTION_TYPE) {
    const tmp = {
      ...state,
      entity: state?.entity,
      order: {
        ...state?.order,
        payment: undefined
      },
      flow: {
        ...state?.flow,
        viewCommon: {
          ...state?.flow.viewCommon,
          liquidClaimReviewInfoFirstNormalFlg: false
        }
      }
    } as Parameters<typeof root>[0];

    return reducer(tmp, action);
  }
  return reducer(state, action);
};

// 永続化設定（sessionStorage）
const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['order', 'flow', 'error', 'entity']
};
const persistedReducer = persistReducer(persistConfig, resettableReducer(root));

/**
 * Stateを一括初期化アクション識別子
 */
const RESET_ACTION_TYPE = 'reset';
/**
 * Stateを一括初期化アクション識別子(リクエストパラメータ除外)
 */
const RESET_EXCEPT_REQUEST_PARAM_ACTION_TYPE = 'resetExceptRequestParam';
/**
 * 注文エラー時State初期化アクション識別子
 */
const RESET_ORDER_ERROR_ACTION_TYPE = 'resetOrderError';

/**
 * 一括初期化アクション
 */
export const reset = createAction(RESET_ACTION_TYPE);
/**
 * 一括初期化アクション(リクエストパラメータ除外)
 */
export const resetExceptRequestParam = createAction(RESET_EXCEPT_REQUEST_PARAM_ACTION_TYPE);
/**
 *注文エラー時State初期化アクション
 */
export const resetOrderError = createAction(RESET_ORDER_ERROR_ACTION_TYPE);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: REACT_APP_ENV !== 'prod',
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
});

export type RootStore = typeof store;

export type RootState = ReturnType<RootStore['getState']>;

export type AppDispatch = typeof store.dispatch;

export type AsyncThunkConfig<T = unknown, U = unknown> = {
  state: RootState;
  dispatch: AppDispatch;
  extra: U;
  rejectValue: T;
};
