import type { RootState } from '@/store';

/**
 * BFF-DCP-001: 適用可能違約金即時ポイント一覧取得API
 * @param state - ステート
 * @returns
 */
export const getPenaltyImmediatePointsResult = (
  state: RootState
): RootState['entity']['discountCampaignService']['getPenaltyImmediatePoints'] =>
  state.entity.discountCampaignService.getPenaltyImmediatePoints;

/**
 * BFF-DCP-002: 割引施策一覧取得API
 * @param state - ステート
 * @returns
 */
export const getCouponListResult = (
  state: RootState
): RootState['entity']['discountCampaignService']['getCouponList'] =>
  state.entity.discountCampaignService.getCouponList;

/**
 * BFF-DCP-003: 割引サービス情報取得API
 * @param state - ステート
 * @returns
 */
export const getDiscountServiceResult = (
  state: RootState
): RootState['entity']['discountCampaignService']['getDiscountService'] =>
  state.entity.discountCampaignService.getDiscountService;

/**
 * BFF-DCP-004: 後日キャンペーン適用情報取得API
 * @param state - ステート
 * @returns
 */
export const getAfterDayCampaignResult = (
  state: RootState
): RootState['entity']['discountCampaignService']['getAfterDayCampaign'] =>
  state.entity.discountCampaignService.getAfterDayCampaign;

/**
 * BFF-DCP-005: 付加サービス情報取得API
 * @param state - ステート
 * @returns
 */
export const getAdditionalServiceResult = (
  state: RootState
): RootState['entity']['discountCampaignService']['getAdditionalService'] =>
  state.entity.discountCampaignService.getAdditionalService;

/**
 * BFF-DCP-007: 金融割情報取得API
 * @param state - ステート
 * @returns
 */
export const getFinancialDiscountResult = (
  state: RootState
): RootState['entity']['discountCampaignService']['getFinanciallyDiscountService'] =>
  state.entity.discountCampaignService.getFinanciallyDiscountService;
