import { order } from 'ols-order-webservice-typescript-client';
import { AreaCode } from '@/typings/AreaCode';
import { ContractType } from '@/typings/ContractType';
import { ContractBranchCode } from '@/typings/ContractBranchCode';
import { NetworkType } from '@/typings/NetworkType';
import { consoleLog } from '@/utils/functions';
import { ProductType } from '@/typings/ProductType';
import { PaymentTimes } from '@/typings/PaymentTimes';

const { DiscountCampaignApi } = order;

/**
 * BFF-DCP-002: 割引施策一覧取得API引数
 */
export type GetCouponListParameter = {
  /**
   * 移動機単品商品コード
   */
  mobileDeviceCode: string;
  /**
   * 契約者生年月日
   */
  contractorBirthday?: string;
  /**
   * 契約種別
   */
  contractType: ContractType;
  /**
   * クーポンNo
   */
  couponNo: string;
  /**
   * ネットワーク区分
   */
  networkType: NetworkType;
  /**
   * データプランコード
   */
  dataPlanCode?: string;
  /**
   * 通話オプションコード
   */
  callOptionCode?: string;
  /**
   * 年契約コード
   */
  annualContractCode: string;
  /**
   * WIN/ISシリーズ/iPhone
   */
  // fixme 【501】 winIsSeriesDeviceCategory をEnum化する
  winIsSeriesDeviceCategory?: '1' | '2' | '3' | '4' | '5';
  /**
   * シングル端末種類
   */
  deviceIpadType: '1' | '2' | '3' | '4';
  /**
   * 支払い回数
   */
  paymentTimes: '1' | '12' | '24' | '36' | '48';
  /**
   * 残価設定型割賦申込有無
   */
  deferredPaymentInstallmentFlag: '0' | '1';
  /**
   * 契約支社
   */
  contractBranchCode?: ContractBranchCode;
  /**
   * 契約都道府県
   */
  areaCodeCode?: AreaCode;
  /**
   * 代理店コード
   */
  agencyCode?: string;
  /**
   * au契約ID
   */
  auContractId?: string;
  /**
   * 利用者生年月日
   */
  userBirthday?: string;
  /**
   * MNP予約番号
   */
  mnpReservationNo?: string;

  /**
   * MNP移転元事業者コード
   */
  mnpMnoNo?: string;
  /**
   * KDDI/OCT区分
   */
  kddioct?: string;
  /**
   * 現在利用中の他社携帯電話番号
   */
  mnpPhoneNo?: string;

  /**
   * MVNO事業者識別コード
   */
  mvnoCode?: string;
  /**
   * インターネット接続サービスコード
   */
  internetConnectServiceCode?: string;
  /**
   * 故障紛失サポートコード
   */
  afterCareSaleServiceCode?: string;
  /**
   * フィルタリングサービスコード
   */
  filteringServiceCode?: string;
  /**
   * 購入品種類
   */
  productType?: ProductType;
  /**
   * 毎月割キャンペーン施策番号
   */
  monthlyDiscountCampaignNo?: string;
};

/**
 * BFF-DCP-003: 割引サービス情報取得API引数
 */
export type GetDiscountServiceParameter = {
  /**
   *  auContractId au契約ID
   */
  auContractId: string;
  /**
   * dataPlanCode ユーザーが選択したデータプランのデータプランコードを設定します。 半角の大文字英数字で指定してください。
   */
  dataPlanCode: string;
  /**
   *  callOptionCode ユーザーが選択した通話オプションの通話オプションコードを設定します。 半角の大文字英数字で指定してください。
   */
  callOptionCode: string;
  /**
   *  networkType 販売商品情報に含まれるネットワーク区分を指定します。   * \&#39;0\&#39; - 3G   * \&#39;1\&#39; - LTE端末   * \&#39;2\&#39; - VoLTE端末   * \&#39;8\&#39; - 5G   * \&#39;9\&#39; - 5GSA   * \&#39;*\&#39; - 上記以外の値
   */
  networkType: '0' | '1' | '2' | '8' | '9' | '*';
  /**
   *  familyDiscountLineCount 家族割プラスカウント対象回線数   BFF-USR-001:アカウント取得APIから取得したfamilyDiscountPlusLineCountを指定してください。
   */
  familyDiscountLineCount?: string;
};

/**
 * BFF-DCP-004: 後日キャンペーン適用情報取得API引数
 */
export type GetAfterDayCampaignParameter = {
  contractType: '1' | '2' | '3' | '4' | '5' | '8';
  networkType: '0' | '1' | '2' | '8' | '9' | '*';
  romIcCardDeviceCategory:
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12'
    | '13'
    | '14'
    | '15'
    | ' ';
  productStockCode: string;
  contractorBirthday: string;
  eSimFlag?: boolean;
  densyu?: string;
  mobileDeviceJudgment?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
  productAttribute?: string;
  microSimType?: '0' | '1';
  auPhoneUsePeriod?: string;
  mvnoCode?: string;
  // fixme 【501】 winIsSeriesDeviceCategory をEnum化する
  winIsSeriesDeviceCategory?: '1' | '2' | '3' | '4' | '5';
  simType?: '1' | '2' | '3';
  userBirthday?: string;
  singleDeviceType?: '1' | '2' | '3' | '4';
};

/**
 * BFF-DCP-005: 付加サービス情報取得API
 */
export type GetAdditionalServiceParameter = {
  auContractId?: string;
  dataPlanCode: string;
  callOptionCode: string;
  networkType: '0' | '1' | '2' | '8' | '9' | '*';
  contractType: ContractType;
  contractorBirthday: string;
  userBirthday?: string;
  mobileDeviceCode?: string;
  // fixme 【501】 winIsSeriesDeviceCategory をEnum化する
  winIsSeriesDeviceCategory?: '1' | '2' | '3' | '4' | '5';
  contractBranchCode?: ContractBranchCode;
  mvnoCode?: string;
  mnpReservationNo?: string;
  mnpMnoNo?: string;
  contractorCategory?: '1' | '2' | '4';
  romIcCardDeviceCategory?:
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '7'
    | '8'
    | ' '
    | '9'
    | '6'
    | '10'
    | '11'
    | '12'
    | '13'
    | '14'
    | '15';
  eSimFlag?: boolean;
  densyu?: string;
  mobileDeviceJudgment?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
  productAttribute?: string;
  microSimType?: '0' | '1';
  auPhoneUsePeriod?: string;
  singleDeviceType?: '1' | '2' | '3' | '4';
};

/**
 * BFF-DCP-007: 金融割情報取得API
 */
export type GetFinancialDiscountParameter = {
  auContractId: string;
  dataPlanCode: string;
  callOptionCode: string;
  networkType: NetworkType;
};

export class DiscountCampaignApiService {
  private static instance: DiscountCampaignApiService;

  private api: order.DiscountCampaignApi;

  private constructor(config: order.Configuration) {
    this.api = new DiscountCampaignApi(config);
  }

  public static getInstance(config: order.Configuration): DiscountCampaignApiService {
    if (!this.instance) {
      this.instance = new DiscountCampaignApiService(config);
    }

    return this.instance;
  }

  /**
   * BFF-DCP-001: 適用可能違約金即時ポイント一覧取得API
   * @param contractType -契約種別
   * @param mobileDeviceCode -移動機単品商品コード
   * @param contractorBirthday -契約者生年月日
   * @param networkType -ネットワーク区分
   * @param dataPlanCode -データプランコード
   * @param contractBranchCode -au契約ID
   * @param paymentTimes -支払い回数
   * @param deferredPaymentInstallmentFlag -残価設定型割賦申込有無
   * @param annualContractCode -年契約コード
   * @param callOptionCode -通話オプションコード
   * @param areaCodeCode -契約都道府県
   * @param agencyCode -代理店コード
   * @param auContractId -au契約ID
   * @param userBirthday -利用者生年月日
   * @param mnpReservationNo -MNP予約番号
   * @param kddioct -KDDI/OCT区分
   * @param mvnoCode -MVNO事業者識別コード
   * @param internetConnectServiceCode -ユーザーが選択したインターネット接続サービスのコード
   * @param afterCareSaleServiceCode -ユーザーが選択した故障紛失サポートコード
   * @param filteringServiceCode -ユーザーが選択したフィルタリングサービスコード
   * @returns
   */
  getPenaltyImmediatePoints(
    contractType: ContractType,
    mobileDeviceCode: string,
    networkType: NetworkType,
    paymentTimes: PaymentTimes,
    deferredPaymentInstallmentFlag: '0' | '1',
    contractBranchCode: ContractBranchCode | undefined,
    contractorBirthday?: string,
    callOptionCode?: string,
    annualContractCode?: string,
    dataPlanCode?: string,
    areaCodeCode?: AreaCode,
    agencyCode?: string,
    auContractId?: string,
    userBirthday?: string,
    mnpReservationNo?: string,
    mnpMnoNo?: string,
    kddioct?: string,
    mnpPhoneNo?: string,
    mvnoCode?: string,
    internetConnectServiceCode?: string,
    afterCareSaleServiceCode?: string,
    filteringServiceCode?: string
  ): Promise<order.InstantPointsGetResponseV1> {
    consoleLog(
      'BFF-DCP-001: 適用可能違約金即時ポイント一覧取得API call',
      contractType,
      mobileDeviceCode,
      networkType,
      paymentTimes,
      deferredPaymentInstallmentFlag,
      contractBranchCode,
      areaCodeCode,
      agencyCode,
      auContractId,
      contractorBirthday,
      userBirthday,
      mnpReservationNo,
      mnpMnoNo,
      kddioct,
      mnpPhoneNo,
      mvnoCode,
      dataPlanCode,
      annualContractCode,
      callOptionCode,
      internetConnectServiceCode,
      afterCareSaleServiceCode,
      filteringServiceCode
    );
    return this.api
      .getPenaltyImmediatePointsV1(
        contractType,
        mobileDeviceCode,
        paymentTimes,
        deferredPaymentInstallmentFlag,
        contractBranchCode,
        areaCodeCode,
        agencyCode,
        auContractId,
        contractorBirthday,
        userBirthday,
        mnpReservationNo,
        mnpMnoNo,
        kddioct,
        mnpPhoneNo,
        networkType,
        mvnoCode,
        dataPlanCode,
        annualContractCode,
        callOptionCode,
        internetConnectServiceCode,
        afterCareSaleServiceCode,
        filteringServiceCode
      )
      .then((res) => res.data);
  }

  /**
   * BFF-DCP-002: 割引施策一覧取得API
   * @param getCouponListParameter -BFF-DCP-002: 割引施策一覧取得API引数
   * @returns
   */
  getCouponList(getCouponListParameter: GetCouponListParameter): Promise<order.CandidateCouponsGetResponseV1> {
    consoleLog('BFF-DCP-002: 割引施策一覧取得API call', getCouponListParameter);
    return this.api
      .getCouponListV1(
        getCouponListParameter.mobileDeviceCode,
        getCouponListParameter.contractType,
        getCouponListParameter.couponNo,
        getCouponListParameter.paymentTimes,
        getCouponListParameter.deferredPaymentInstallmentFlag,
        getCouponListParameter.contractBranchCode,
        getCouponListParameter.areaCodeCode,
        getCouponListParameter.agencyCode,
        getCouponListParameter.auContractId,
        getCouponListParameter.contractorBirthday,
        getCouponListParameter.userBirthday,
        getCouponListParameter.monthlyDiscountCampaignNo,
        getCouponListParameter.mnpReservationNo,
        getCouponListParameter.mnpMnoNo,
        getCouponListParameter.kddioct,
        getCouponListParameter.mnpPhoneNo,
        getCouponListParameter.networkType,
        getCouponListParameter.mvnoCode,
        getCouponListParameter.dataPlanCode,
        getCouponListParameter.callOptionCode,
        getCouponListParameter.annualContractCode,
        getCouponListParameter.internetConnectServiceCode,
        getCouponListParameter.afterCareSaleServiceCode,
        getCouponListParameter.filteringServiceCode,
        getCouponListParameter.winIsSeriesDeviceCategory,
        getCouponListParameter.deviceIpadType,
        getCouponListParameter.productType
      )
      .then((res) => res.data);
  }

  /**
   * BFF-DCP-003: 割引サービス情報取得API
   * @param getDiscountService -BFF-DCP-003: 割引サービス情報取得API引数
   * @returns
   */
  getDiscountService(
    getDiscountServiceParameter: GetDiscountServiceParameter
  ): Promise<order.DiscountServiceGetResponseV1> {
    consoleLog('BFF-DCP-003: 割引サービス情報取得API call', getDiscountServiceParameter);
    return this.api
      .getDiscountServiceV1(
        getDiscountServiceParameter.auContractId,
        getDiscountServiceParameter.dataPlanCode,
        getDiscountServiceParameter.callOptionCode,
        getDiscountServiceParameter.networkType,
        getDiscountServiceParameter.familyDiscountLineCount
      )
      .then((res) => res.data);
  }

  /**
   * BFF-DCP-004: 後日キャンペーン適用情報取得API
   * @param getDiscountService -BFF-DCP-004: 後日キャンペーン適用情報取得API引数
   * @returns
   */
  getAfterDayCampaign(
    getAfterDayCampaignParameter: GetAfterDayCampaignParameter
  ): Promise<order.AfterDayCampaignGetResponseV1> {
    consoleLog('BFF-DCP-004: 後日キャンペーン適用情報取得API call', getAfterDayCampaignParameter);
    return this.api
      .getAfterDayCampaignV1(
        getAfterDayCampaignParameter.contractType,
        getAfterDayCampaignParameter.networkType,
        getAfterDayCampaignParameter.productStockCode,
        getAfterDayCampaignParameter.contractorBirthday,
        getAfterDayCampaignParameter.romIcCardDeviceCategory,
        getAfterDayCampaignParameter.eSimFlag,
        getAfterDayCampaignParameter.densyu,
        getAfterDayCampaignParameter.mobileDeviceJudgment,
        getAfterDayCampaignParameter.productAttribute,
        getAfterDayCampaignParameter.microSimType,
        getAfterDayCampaignParameter.auPhoneUsePeriod,
        getAfterDayCampaignParameter.mvnoCode,
        getAfterDayCampaignParameter.winIsSeriesDeviceCategory,
        getAfterDayCampaignParameter.simType,
        getAfterDayCampaignParameter.userBirthday,
        getAfterDayCampaignParameter.singleDeviceType
      )
      .then((res) => res.data);
  }

  /**
   * BFF-DCP-005: 付加サービス情報取得API
   * @param getDiscountService -BFF-DCP-004: 後日キャンペーン適用情報取得API引数
   * @returns
   */
  getAdditionalService(
    getAdditionalServiceParameter: GetAdditionalServiceParameter
  ): Promise<order.AdditionalServiceGetResponseV1> {
    consoleLog('BFF-DCP-005: 付加サービス情報取得API call', getAdditionalServiceParameter);
    return this.api
      .getAdditionalServiceV1(
        getAdditionalServiceParameter.dataPlanCode,
        getAdditionalServiceParameter.callOptionCode,
        getAdditionalServiceParameter.networkType,
        getAdditionalServiceParameter.contractType,
        getAdditionalServiceParameter.auContractId,
        getAdditionalServiceParameter.contractorBirthday,
        getAdditionalServiceParameter.userBirthday,
        getAdditionalServiceParameter.mobileDeviceCode,
        getAdditionalServiceParameter.winIsSeriesDeviceCategory,
        getAdditionalServiceParameter.contractBranchCode,
        getAdditionalServiceParameter.mvnoCode,
        getAdditionalServiceParameter.mnpReservationNo,
        getAdditionalServiceParameter.mnpMnoNo,
        getAdditionalServiceParameter.contractorCategory,
        getAdditionalServiceParameter.romIcCardDeviceCategory,
        getAdditionalServiceParameter.eSimFlag,
        getAdditionalServiceParameter.densyu,
        getAdditionalServiceParameter.mobileDeviceJudgment,
        getAdditionalServiceParameter.productAttribute,
        getAdditionalServiceParameter.microSimType,
        getAdditionalServiceParameter.auPhoneUsePeriod,
        getAdditionalServiceParameter.singleDeviceType
      )
      .then((res) => res.data);
  }

  /**
   * BFF-DCP-007: 金融割情報取得API
   * @param getFinancialDiscountParameter - BFF-DCP-007: 金融割情報取得API引数
   * @returns
   */
  getFinancialDiscountService(
    getFinancialDiscountServiceParameter: GetFinancialDiscountParameter
  ): Promise<order.FinancialDiscountGetResponseV1> {
    consoleLog('BFF-DCP-007: 金融割情報取得API call', getFinancialDiscountServiceParameter);
    return this.api
      .getFinancialDiscountV1(
        getFinancialDiscountServiceParameter.auContractId,
        getFinancialDiscountServiceParameter.dataPlanCode,
        getFinancialDiscountServiceParameter.callOptionCode,
        getFinancialDiscountServiceParameter.networkType
      )
      .then((res) => res.data);
  }
}
