import { REACT_APP_ENABLE_CONSOLE_LOG } from '@/config/env';

/**
 * 何も処理をしない関数
 *
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const NOP: () => void = () => {};

/**
 * 引数で指定された数値の合算値を返却する関数
 * ※主にreduce関数で指定するコールバック関数として使用。
 * @param a - 数値a
 * @param b - 数値b
 * @returns 引数で指定された数値の合算値（数値a + 数値b）
 */
export const sum = (a: number, b: number): number => a + b;

/**
 * コンソールログ出力
 * @param obj - 出力パラメータ
 * @returns
 */
export const consoleLog = (...obj: unknown[]): void => {
  if (REACT_APP_ENABLE_CONSOLE_LOG === 'false') return;
  // eslint-disable-next-line no-console
  console.log(...obj);
};

/**
 * 引数の値が undefined の場合は空文字列に変換した値を取得する
 * @param val - 数値文字列 or undefined
 * @returns 変換した文字列
 */
export const getStringValue = (val: string | undefined): string => val || '';

/**
 * 引数の値が undefined の場合は 0 に変換した数値を取得する
 * @param val - 数値 or undefined
 * @returns 変換した数値
 */
export const getNumberValue = (val: string | undefined): number => (val ? Number(val) : 0);

/**
 * 引数の値が 空文字 の場合はundefinedに変換した値を取得する
 * @param val - 変換元変数
 * @returns 変換後変数
 */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
export const getUndefValue = (val: any): any => {
  if (!val && val !== 0 && val !== false) {
    return undefined;
  }
  return val;
};

/**
 * 引数の値が 空文字 の場合はundefinedに変換した値を取得する(空配列を含む)
 * @param val - 変換元変数
 * @returns 変換後変数
 */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
export const getUndefArrayAndValue = (val: any): any => {
  if (val === {} || val.length === 0 || (!val && val !== 0 && val !== false)) {
    return undefined;
  }
  return val;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
export const isUndefined = (val: any): any => {
  if (val === undefined) {
    return true;
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
export const isNumber = (val: any): any => {
  if (val === undefined) {
    return undefined;
  }
  return Number(val);
};
