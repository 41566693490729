import { AppDispatch } from '@/store';
import { ShopSelectionScreenStatuses } from './shopSelectionScreenStatusSlice';
import { resetShopSelectionScreenStatus, setShopSelectionScreenStatus } from './actions';

/**
 * セクション状態更新オペレータ
 * @param shopSelectionScreenStatus - セクション状態
 * @returns
 */
export const set = (shopSelectionScreenStatus: Partial<ShopSelectionScreenStatuses>) => (
  dispatch: AppDispatch
): ReturnType<typeof setShopSelectionScreenStatus> => dispatch(setShopSelectionScreenStatus(shopSelectionScreenStatus));

/**
 * セクション状態初期化オペレータ
 * @returns
 */
export const reset = () => (dispatch: AppDispatch): ReturnType<typeof resetShopSelectionScreenStatus> =>
  dispatch(resetShopSelectionScreenStatus());
